/*

Template:  Work House - Industry Base HTML Template
Author: https://www.potenzaglobalsolutions.com
Version: 1.0
Design and Developed by: https://www.potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template. Please do not change anything here! write in a custom.css file if required!

*/

/*================================================
[  Table of contents  ]
================================================

:: General

======================================
[ End table content ]
======================================*/

/*************************
    General
*************************/

/*Template Google font*/
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,500,600,700|Nunito&display=swap");

body {
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
}
p {
  color: #626262;
}
a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:focus {
  text-decoration: none !important;
}
a:focus,
a:hover {
  color: #3952d0;
  text-decoration: none !important;
}
a,
button,
input {
  outline: medium none !important;
  color: #3952d0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cabin", sans-serif;
  font-weight: normal;
  color: #333;
  margin-top: 0px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}
h3 {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
h4 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}
h5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}
h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.fw-1 {
  font-weight: 100 !important;
}
.fw-2 {
  font-weight: 200 !important;
}
.fw-3 {
  font-weight: 300 !important;
}
.fw-4 {
  font-weight: 400 !important;
}
.fw-5 {
  font-weight: 500 !important;
}
.fw-6 {
  font-weight: 600 !important;
}
.fw-7 {
  font-weight: 700 !important;
}
.fw-8 {
  font-weight: 800 !important;
}
.fw-9 {
  font-weight: 900 !important;
}

.line-h-1 {
  line-height: 10px;
}
.line-h-2 {
  line-height: 20px;
}
.line-h-3 {
  line-height: 30px;
}
.line-h-4 {
  line-height: 40px;
}
.line-h-5 {
  line-height: 50px;
}
.line-h-6 {
  line-height: 60px;
}
.line-h-7 {
  line-height: 70px;
}
.line-h-8 {
  line-height: 80px;
}
.line-h-9 {
  line-height: 90px;
}

.l-space-0 {
  letter-spacing: 0px;
}
.l-space-1 {
  letter-spacing: 1px;
}
.l-space-2 {
  letter-spacing: 2px;
}
.l-space-3 {
  letter-spacing: 3px;
}
.l-space-4 {
  letter-spacing: 4px;
}
.l-space-5 {
  letter-spacing: 5px;
}
.l-space-6 {
  letter-spacing: 6px;
}
.l-space-7 {
  letter-spacing: 7px;
}
.l-space-8 {
  letter-spacing: 8px;
}
.l-space-9 {
  letter-spacing: 9px;
}
.l-space-10 {
  letter-spacing: 10px;
}
.l-space-minus-1 {
  letter-spacing: -1px;
}
.l-space-minus-2 {
  letter-spacing: -2px;
}
.l-space-minus-3 {
  letter-spacing: -3px;
}
.l-space-minus-4 {
  letter-spacing: -4px;
}
.l-space-minus-5 {
  letter-spacing: -5px;
}
.l-space-minus-6 {
  letter-spacing: -6px;
}
.l-space-minus-7 {
  letter-spacing: -7px;
}
.l-space-minus-8 {
  letter-spacing: -8px;
}
.l-space-minus-9 {
  letter-spacing: -9px;
}
.l-space-minus-10 {
  letter-spacing: -10px;
}

.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-34 {
  font-size: 34px !important;
}
.font-36 {
  font-size: 36px !important;
}
.font-40 {
  font-size: 40px !important;
}
.font-46 {
  font-size: 46px !important;
}
.font-50 {
  font-size: 50px !important;
}
.font-56 {
  font-size: 56px !important;
}
.font-60 {
  font-size: 60px !important;
}
.font-66 {
  font-size: 66px !important;
}
.font-70 {
  font-size: 70px !important;
}

ul,
ol,
dl {
  list-style-type: none;
  margin-bottom: 0;
}
hr {
  margin: 0;
  padding: 0px;
  border-bottom: 1px dashed #eceff8;
  border-top: 0px;
}

*::-moz-selection {
  background: #3952d0;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #3952d0;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #3952d0;
  color: #fff;
  text-shadow: none;
}

/***************************
Page section margin padding
****************************/
.page-section-ptb {
  padding: 80px 0;
}
.page-section-pt {
  padding: 80px 0 0;
}
.page-section-pb {
  padding: 0 0 80px;
}

.page-section-1-ptb {
  padding: 120px 0;
}
.page-section-1-pt {
  padding: 120px 0 0;
}
.page-section-1-pb {
  padding: 0 0 120px;
}

.page-section-2-ptb {
  padding: 60px 0;
}
.page-section-2-pt {
  padding: 60px 0 0;
}
.page-section-2-pb {
  padding: 0 0 60px;
}

/*************************
    Text color Hover
*************************/
.text-theme-hover:hover {
  color: #3952d0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.text-white-hover:hover {
  color: #fff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.text-dark-hover:hover {
  color: #363636;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.text-gray-hover:hover {
  color: #999;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.text-light-gray-hover:hover {
  color: #f1f1f1;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

/*************************
    Text color
*************************/
.text-theme {
  color: #3952d0;
}
.text-white {
  color: #fff;
}
.text-dark {
  color: #363636 !important;
}
.text-gray {
  color: #999;
}
.text-light-gray {
  color: #f1f1f1;
}

/*************************
    link color
*************************/
a.link-theme {
  color: #3952d0;
}
a.link-white {
  color: #fff;
}
a.link-dark {
  color: #363636;
}
a.link-gray {
  color: #999;
}
a.link-light-gray {
  color: #f1f1f1;
}

/*************************
    Background color Hover
*************************/
.bg-none-hover:hover {
  background-color: none !important;
}
.bg-theme-hover:active,
.bg-theme-hover:focus,
.bg-theme-hover:hover {
  background-color: #3952d0;
  color: #fff;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.bg-white-hover:active,
.bg-white-hover:focus,
.bg-white-hover:hover {
  background-color: #fff;
  color: #363636;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.bg-dark-hover:active,
.bg-dark-hover:focus,
.bg-dark-hover:hover {
  background-color: #363636;
  color: #fff !important;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.bg-gray-hover:active,
.bg-gray-hover:focus,
.bg-gray-hover:hover {
  background-color: #f7f7f7;
  color: #fff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.bg-dark-gray-hover:active,
.bg-dark-gray-hover:focus,
.bg-dark-gray-hover:hover {
  background-color: #666666;
  color: #363636;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

/*************************
    Background color
************************/
.bg-none {
  background: none !important;
}
.bg-theme {
  background: #3952d0;
}
.bg-dark {
  background: #120b00 !important;
}
.bg-white {
  background: #fff;
}
.bg-gray {
  background: #f7f7f7;
}
.bg-dark-gray {
  background: #666666;
}

/*******************
Max width
*******************/
.max-w-300 {
  max-width: 300px;
}
.max-w-350 {
  max-width: 350px;
}
.max-w-400 {
  max-width: 400px;
}
.max-w-450 {
  max-width: 450px;
}
.max-w-500 {
  max-width: 500px;
}
.max-w-550 {
  max-width: 550px;
}
.max-w-600 {
  max-width: 600px;
}
.max-w-650 {
  max-width: 650px;
}

/*************************
    Extra class
*************************/
.o-hidden {
  overflow: hidden !important;
}
.inner-shadow {
  box-shadow: inset 0px 0px 100px 10px rgba(0, 0, 0, 0.3);
}
.inner-shadow-hover:hover {
  box-shadow: inset 0px 0px 100px 10px rgba(0, 0, 0, 0.3);
}
.box-shadow {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.09);
}
.box-shadow-1 {
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.11);
}
.box-shadow-2 {
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
}
.box-shadow-hover:hover {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.09);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.box-shadow-hover-1:hover {
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.11);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.box-shadow-hover-2:hover {
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.img-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 1px 10px 30px 0px;
}
.img-shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 10px 40px 0px;
}
.img-shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.25) 1px 10px 50px 0px;
}
.img-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1px 10px 30px 0px;
}
.img-shadow-1:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 10px 40px 0px;
}
.img-shadow-2:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 1px 10px 50px 0px;
}
.transition {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

/*************************
    Border Radius
 *************************/
.border-radius-none {
  border-radius: 0;
}
.border-radius-1 {
  border-radius: 1px;
}
.border-radius-2 {
  border-radius: 2px;
}
.border-radius-3 {
  border-radius: 3px;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-7 {
  border-radius: 7px;
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-9 {
  border-radius: 9px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-50 {
  border-radius: 50%;
}
.border-radius-100 {
  border-radius: 100%;
}

.border-t {
  border-top: 2px solid #3952d0;
  display: inline-block;
}
.border-b {
  border-bottom: 2px solid #3952d0;
  display: inline-block;
}
.border-l {
  border-left: 2px solid #3952d0;
  display: inline-block;
}
.border-r {
  border-right: 2px solid #3952d0;
  display: inline-block;
}

.border-lr {
  position: relative;
  display: inline-block;
}
.border-lr:before {
  position: absolute;
  background: #fff;
  height: 2px;
  content: "";
  left: -130px;
  width: 90px;
  top: 50%;
  margin-top: -1px;
}
.border-lr:after {
  position: absolute;
  background: #fff;
  height: 2px;
  content: "";
  right: -130px;
  width: 90px;
  top: 50%;
  margin-top: -1px;
}

/*******************************************
             Loading
*******************************************/
#pre-loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
}
#pre-loader img {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 99;
  margin: 0 auto;
}

/*************************
    back-to-top
*************************/
#back-to-top .top {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  margin: 0px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  position: fixed;
  bottom: 105px;
  right: 30px;
  border-radius: 3px;
  z-index: 999;
  background: transparent;
  font-size: 14px;
  background: #3952d0;
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 15px;
}
#back-to-top i {
  padding-top: 2px;
  font-size: 18px;
  line-height: 18px;
}

/*************************
     Margin top
*************************/
.mt-6 {
  margin-top: 60px !important;
}
.mt-7 {
  margin-top: 70px !important;
}
.mt-8 {
  margin-top: 80px !important;
}
.mt-9 {
  margin-top: 90px !important;
}
.mt-10 {
  margin-top: 100px !important;
}
.mt-11 {
  margin-top: 110px !important;
}
.mt-12 {
  margin-top: 120px !important;
}
.mt-13 {
  margin-top: 130px !important;
}
.mt-14 {
  margin-top: 140px !important;
}

/*************************
    Margin right
*************************/
.mr-6 {
  margin-right: 60px !important;
}
.mr-7 {
  margin-right: 70px !important;
}
.mr-8 {
  margin-right: 80px !important;
}
.mr-9 {
  margin-right: 90px !important;
}
.mr-10 {
  margin-right: 100px !important;
}
.mr-11 {
  margin-right: 110px !important;
}
.mr-12 {
  margin-right: 120px !important;
}
.mr-13 {
  margin-right: 130px !important;
}
.mr-14 {
  margin-right: 140px !important;
}

/*************************
    Margin bottom
*************************/
.mb-6 {
  margin-bottom: 60px !important;
}
.mb-7 {
  margin-bottom: 70px !important;
}
.mb-8 {
  margin-bottom: 80px !important;
}
.mb-9 {
  margin-bottom: 90px !important;
}
.mb-10 {
  margin-bottom: 100px !important;
}
.mb-11 {
  margin-bottom: 110px !important;
}
.mb-12 {
  margin-bottom: 120px !important;
}
.mb-13 {
  margin-bottom: 130px !important;
}
.mb-14 {
  margin-bottom: 140px !important;
}

/*************************
    Margin left
*************************/
.ml-6 {
  margin-left: 60px !important;
}
.ml-7 {
  margin-left: 70px !important;
}
.ml-8 {
  margin-left: 80px !important;
}
.ml-9 {
  margin-left: 90px !important;
}
.ml-10 {
  margin-left: 100px !important;
}
.ml-11 {
  margin-left: 110px !important;
}
.ml-12 {
  margin-left: 120px !important;
}
.ml-13 {
  margin-left: 130px !important;
}
.ml-14 {
  margin-left: 140px !important;
}

/*************************
    Padding top
*************************/
.pt-6 {
  padding-top: 60px !important;
}
.pt-7 {
  padding-top: 70px !important;
}
.pt-8 {
  padding-top: 80px !important;
}
.pt-9 {
  padding-top: 90px !important;
}
.pt-10 {
  padding-top: 100px !important;
}
.pt-11 {
  padding-top: 110px !important;
}
.pt-12 {
  padding-top: 120px !important;
}
.pt-13 {
  padding-top: 130px !important;
}
.pt-14 {
  padding-top: 140px !important;
}

/*************************
    Padding right
*************************/
.pr-6 {
  padding-right: 60px !important;
}
.pr-7 {
  padding-right: 70px !important;
}
.pr-8 {
  padding-right: 80px !important;
}
.pr-9 {
  padding-right: 90px !important;
}
.pr-10 {
  padding-right: 100px !important;
}
.pr-11 {
  padding-right: 110px !important;
}
.pr-12 {
  padding-right: 120px !important;
}
.pr-13 {
  padding-right: 130px !important;
}
.pr-14 {
  padding-right: 140px !important;
}

/*************************
    Padding bottom
*************************/
.pb-6 {
  padding-bottom: 60px !important;
}
.pb-7 {
  padding-bottom: 70px !important;
}
.pb-8 {
  padding-bottom: 80px !important;
}
.pb-9 {
  padding-bottom: 90px !important;
}
.pb-10 {
  padding-bottom: 100px !important;
}
.pb-11 {
  padding-bottom: 110px !important;
}
.pb-12 {
  padding-bottom: 120px !important;
}
.pb-13 {
  padding-bottom: 130px !important;
}
.pb-14 {
  padding-bottom: 140px !important;
}

/*************************
    Padding left
*************************/
.pl-6 {
  padding-left: 60px !important;
}
.pl-7 {
  padding-left: 70px !important;
}
.pl-8 {
  padding-left: 80px !important;
}
.pl-9 {
  padding-left: 90px !important;
}
.pl-10 {
  padding-left: 100px !important;
}
.pl-11 {
  padding-left: 110px !important;
}
.pl-12 {
  padding-left: 120px !important;
}
.pl-13 {
  padding-left: 130px !important;
}
.pl-14 {
  padding-left: 140px !important;
}
