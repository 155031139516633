/*
Template:  Work House - Responsive Multi-purpose HTML5 Template
Author: https://www.potenzaglobalsolutions.com
Version: 1.0
Design and Developed by: https://www.potenzaglobalsolutions.com

*/


 /*****************
================================================
 (  Media Queries  )
================================================
 *******************/


/*************************
       1700px
*************************/
@media (max-width: 1700px) {
.portfolio-title { padding: 60px 60px 30px; }

/* about-1 */
.feature-step-2 .feature-step-2-title-left h1 {  font-size: 60px;  line-height: 50px;}

/*footer*/
.scroll-down { bottom: 30px; }

}

@media (max-width: 1500px) {
.portfolio-home .section-title p { font-size: 16px; }

/*home-4  */
.our-blog .blog-box-image { max-height: 262px; }
.our-blog .blog-box-info { max-height: 262px; }
.blog-box-image .portfolio-caption .port-tag li a { font-size: 12px; }
.slider-fade-carousel { height: 100%; }
.our-service { padding-bottom: 60px; }
.objects-left, .objects-right { display: none; }
.mega-menu .drop-down.grid-col-5 { width: 51.666667%; }

/*home 9*/
.resume-contact #map { height: 642px; }

}

@media (max-width: 1400px) {
.portfolio-title { padding:40px 40px 30px; }
.our-blog .blog-box-image { max-height: 240px; }
.our-blog .blog-box-info { max-height: 240px; }
.our-blog .blog-box-info p.pb-2.pt-3 { padding: 10px 0 !important; }
.custom-content-3.hello-content .custom-content-3-image img { margin-top: 150px; vertical-align: bottom; width: 100%; }
.our-blog .blog-box-info span.text-white { display: none; }

/*contact*/
.contact-3-info .contact-add i { float: none; text-align: center; display: block; margin: 0 auto; }
.contact-3-info .contact-add p { display: block; margin-top: 15px; }

/*home-9*/
.image-holder-2-bg { height: 700px; }
.feature-step-2 .feature-step-2-title-2-right h2 {  font-size: 24px;   line-height: 30px;}
.feature-step-2 .feature-step-2-title-2-right h3 {  font-size: 20px;   line-height: 24px;}
.feature-step-2-box {  padding: 50px 30px;}
.resume-page .container-fluid { padding: 0 30px; }
.resume-contact .contact-form-main { padding: 30px; }
.cleaning-bg{ background-position: -250px !important; }

}

@media (max-width: 1272px) {
.our-blog .blog-box-image { max-height: 185px; }
.our-blog .blog-box-info { max-height: 185px; }
.our-blog .blog-box-image .portfolio-caption .port-tag li { margin-top: 0px; }
.our-blog .blog-box-image .portfolio-caption .button-border span { margin-top: 10px; padding: 4px 10px; font-size: 11px; }
.our-blog .blog-box-info a.button.button-white span { padding: 6px 16px; }
.our-blog .blog-box-info a.button.button-white i { padding: 11px; }
.our-blog .blog-box-info h1 { font-size: 20px; line-height: 20px; margin-bottom: 0; }
.our-blog .blog-box-info p { font-size: 14px; }
.our-blog .blog-box-info { padding: 15px 20px; }

/*home-5*/
.team-3 .team-social-icon a i { font-size: 12px; height: 30px; line-height: 31px; width: 30px; }

/*about-1 page*/
.our-history .history-content { padding: 125px 20px; }

/*about me*/
.about-me .maintenance-progress-bar .progress-bar { margin: 0 15px; }

 /*blog timeline*/
.blog.timeline-sidebar .timeline li.timeline-inverted .timeline-badge { left: -50px; }

/*error*/
.error-search-box { width: 91%; }
.mega-menu .drop-down.grid-col-5 { width: 61.666667%; }

/* about-1 */
.feature-step-2 .feature-step-2-title-left h1 {  font-size: 40px;  line-height: 30px;}
.feature-step-2 .feature-step-2-title-2-right h2 {  font-size: 22px;   line-height: 24px;}
.feature-step-2 .feature-step-2-title-2-right h3 {  font-size: 18px;   line-height: 20px;}

}

@media (min-width: 1200px) {
.container { max-width: 1170px;}

}

@media (max-width: 1199px) {
.login-2-social li {margin: 0 3px;}
h2 {font-size: 28px;}
h4 {font-size: 18px;}
.navbar .navbar-nav .nav-link{padding-left:10px; padding-right: 10px;}

/*home2*/
.testimonial-2 .testimonial-center { width: 100%; }
.deal-banner img { width: initial; }

/*home 9*/
.footer-widget-social.pull-right  { float: none !important; }
.blog-info h4 {font-size: 15px;}
.blog-info p {font-size: 16px; padding: 10px 0 20px;}
.feature-text.box, .feature-text.fill-box {padding: 30px;}
.nav.nav-tabs li a {margin-right: 5px; margin-bottom: 5px;}
.happy-clients .nav-tabs li img {height: 80px; width: 80px;}
.team .team-social-icon ul li a i {height: 35px; line-height: 35px; width: 35px; font-size: 14px;}
.team:hover .team-social-icon {bottom: 27%;}
.feature-info h5{font-size: 15px;}
.contact-3 .map-side {height: 808px;}
.contact-4 .map-side {height: 553px;}
.service-blog {padding-top: 0;}
.portfolio-home .isotope.columns-3 .grid-item { width: 50%; }
.portfolio-title { padding:60px 60px 30px; }
.action-box h3 { font-size: 20px; }
.action-box p { font-size: 14px; }
.vertical-header .container-fluid { padding: 0 30px; }
.team.team-round.full-border .team-description { padding: 30px 0; }

/*404 error*/
.error-block { padding: 20px 0  70px 0px; }
.error-block .error-text h2 { font-size: 360px; }
.error-block .error-text span { font-size: 104px; }
.error-text-02 h2 { font-size: 360px; }
.error-text-02 span { font-size: 104px; }
.add-banner-2 .add-banner-content h2 { font-size: 42px; line-height: 42px; }

/*team*/
.social-icons li{ margin: 0 7px 7px 0; }

/*banner*/
#main-slider .heading-extra-large{ font-size: 58px; line-height: 60px; }
#main-slider .heading-large { font-size: 60px;  line-height: 65px; }
#main-slider .heading-small { font-size: 16px; line-height: 24px; }
#main-slider .heading-med { font-size: 30px; line-height: 36px;}
.mega-menu .menu-links > li > a{ font-size: 12px }
.mega-menu .menu-logo > li > a img { height: 32px; }
.banner-patarn {height: 80px; margin-top: -6.5%;}

/*index-10 Welder*/
#main-slider.welder .swiper-slide h1 { font-size: 58px; line-height: 67px; margin-bottom: 20px;}
#main-slider.welder .swiper-slide h3 { font-size: 30px; line-height: 36px; margin-bottom: 20px;}

/*maintenance*/
.section-title {margin-bottom: 30px;}
.isotope.columns-4 .grid-item { width: 33.333%; padding: 10px; }
.custom-content { margin-top: -110px;}
#main-slider.gardener .heading-large { font-size: 60px; line-height: 70px; }
#main-slider.carpenter .heading-large { font-size: 60px; line-height: 70px; }

/*carwash*/
#main-slider.carwash .heading-large { font-size: 36px;line-height: 42px;}
#main-slider.carwash .swipeinner .card-body p{ font-size: 12px; }
#main-slider.carwash .swipeinner .card-body span { font-size: 60px; }
#main-slider.carwash .banner-box, #main-slider.carwash .swipeinner .card-body{ padding: 15px; }

/*index-05 Moving*/
#main-slider.moving .border-l:before { position: absolute; content: ""; left: 0; top: 10px; width: 5px; height: 110px; background: #d12326;}
#main-slider.moving .border-r:before { position: absolute; content: ""; right: 0; top: 10px; width: 5px; height: 110px; background: #d12326;}
#main-slider.moving .heading-large { font-size: 60px; line-height: 65px; }
.hover-overlay .icon-circle { width: 40px; height: 40px; line-height: 38px; margin: 10px 5px; font-size: 18px;}

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

/* about-1 */
.feature-step-2 .feature-step-2-title-2-right h2 {  padding-bottom: 5px; margin-bottom: 5px; }
.feature-step-2 .feature-step-2-title-2-right h3 {  margin-bottom: 5px;}
.feature-step-2 .feature-step-2-title-2-right p{line-height: 20px;}

/* blog-2 */
.blog-box.blog-2 .blog-info { padding: 20px;}

/*new-bs-4*/
.big-action-box span.big-text { font-size: 46px; }
.counter label{ font-size: 12px;  }
.nav.nav-tabs li a { margin-right: 5px; margin-bottom: 0px;}

}

@media (max-width: 1025px) {
h4 {font-size: 16px;}
.portfolio-title { padding:30px 30px 30px; }
.big-button {   padding: 28px 16px;  font-size: 20px; }

/*error*/
.error-search-box { width: 100%; text-align: center; }
.clients-logo.line { width: 28.3333%; }
.clients-logo.line img { width: 90%; }

/*blog timeline*/
.blog .timeline > li > .timeline-badge { right: -66px; }
.blog .timeline > li.timeline-inverted > .timeline-badge { left: -63px; }
.deal-banner .caption { margin-top: 50px; margin-bottom: 50px; }

/*process*/
.process-list { padding: 50px 20px; }
.action-box.big h2{ font-size: 40px; }
.action-box p { font-size: 14px; }

/*.countdown-timer*/
.countdown.small span{ font-size: 35px; line-height: 38px; }
.countdown span{ font-size: 40px; }
.countdown.round.medium span{ font-size: 45px; }
.countdown.medium span { font-size: 45px; line-height: 50px; }
.countdown.round.large{ width: 188px; height: 188px; }
.countdown.large span{ font-size: 80px; }
.mega-menu .menu-links > li > a{ padding:0px 10px; }
.feature-text.box-shadow, .special-feature .feature-text { padding:30px; }
.special-feature{ top: -100px; }
.banner-patarn{ margin-top: -6.3%; }

/*action-box*/
.action-box{ font-size: 20px; }

/*new-bs-4*/
.btn { padding: 8px 20px; line-height: 2; }
.btn-outline-light{ height: 44px; line-height: 1.9 !important; }
.team-card .card-body ul { padding-left: 20px; }
.social-icons li { margin: 0 18px 7px 0; }
.nav.nav-tabs li a { margin-right: 5px; margin-bottom: 0px; }

/*index-03 Mechanic*/
#main-slider.mechanic .heading-extra-large{ font-size: 50px; line-height: 55px; }

/*index-04 Gardener*/
#main-slider.gardener .heading-large { font-size: 60px; line-height: 70px;}
#main-slider.gardener .btn.btn-lg { margin-top: 15px; padding: 8px 35px; line-height: 30px; font-size: 15px; }

/*index-05 Moving*/
#main-slider.moving .border-l:before { position: absolute; content: ""; left: 0; top: 10px; width: 5px; height: 110px; background: #d12326;}
#main-slider.moving .border-r:before { position: absolute; content: ""; right: 0; top: 10px; width: 5px; height: 110px; background: #d12326;}
#main-slider.moving .heading-large { font-size: 60px; line-height: 65px; }

/*index-09 Carpenter*/
#main-slider.carpenter .heading-med { font-size: 36px; line-height: 40px;}
#main-slider.carpenter .heading-large { font-size: 60px; line-height: 65px;}
#main-slider.carpenter .btn.btn-lg { padding: 8px 35px; line-height: 30px; font-size: 15px; }

}

@media only screen and (min-width: 768px) and (max-width: 999px) {
#main-slider .carousel-control i{line-height: 36px; padding: 15px 10px;}

}

@media(max-width:991px) {

/*************************
     Margin top
*************************/
.md-mt-0 { margin-top: 0 !important; }
.md-mt-1 { margin-top: 10px !important; }
.md-mt-2 { margin-top: 20px !important; }
.md-mt-3 { margin-top: 30px !important; }
.md-mt-4 { margin-top: 40px !important; }
.md-mt-5 { margin-top: 50px !important; }

/*************************
    Margin right
*************************/
.md-mr-0 { margin-right: 0!important }
.md-mr-1 { margin-right: 10px !important; }
.md-mr-2 { margin-right: 20px !important; }
.md-mr-3 { margin-right: 30px !important; }
.md-mr-4 { margin-right: 40px !important; }
.md-mr-5 { margin-right: 50px !important; }

/*************************
    Margin bottom
*************************/
.md-mb-0 { margin-bottom: 0!important }
.md-mb-1 { margin-bottom: 10px !important; }
.md-mb-2 { margin-bottom: 20px !important; }
.md-mb-3 { margin-bottom: 30px !important; }
.md-mb-4 { margin-bottom: 40px !important; }
.md-mb-5 { margin-bottom: 50px !important; }

/*************************
    Margin left
*************************/
.md-ml-0 { margin-left: 0!important }
.md-ml-1 { margin-left: 10px !important; }
.md-ml-2 { margin-left: 20px !important; }
.md-ml-3 { margin-left: 30px !important; }
.md-ml-4 { margin-left: 40px !important; }
.md-ml-5 { margin-left: 50px !important; }

/*************************
    Padding top
*************************/
.md-pt-0 { padding-top: 0!important }
.md-pt-1 { padding-top: 10px !important; }
.md-pt-2 { padding-top: 20px !important; }
.md-pt-3 { padding-top: 30px !important; }
.md-pt-4 { padding-top: 40px !important; }
.md-pt-5 { padding-top: 50px !important; }

/*************************
    Padding right
*************************/
.md-pr-0 { padding-right: 0!important }
.md-pr-1 { padding-right: 10px !important; }
.md-pr-2 { padding-right: 20px !important; }
.md-pr-3 { padding-right: 30px !important; }
.md-pr-4 { padding-right: 40px !important; }
.md-pr-5 { padding-right: 50px !important; }

/*************************
    Padding bottom
*************************/
.md-pb-0 { padding-bottom: 0!important }
.md-pb-1 { padding-bottom: 10px !important; }
.md-pb-2 { padding-bottom: 20px !important; }
.md-pb-3 { padding-bottom: 30px !important; }
.md-pb-4 { padding-bottom: 40px !important; }
.md-pb-5 { padding-bottom: 50px !important; }

/*************************
    Padding left
*************************/
.md-pl-0 { padding-left: 0!important }
.md-pl-1 { padding-left: 10px !important; }
.md-pl-2 { padding-left: 20px !important; }
.md-pl-3 { padding-left: 30px !important; }
.md-pl-4 { padding-left: 40px !important; }
.md-pl-5 { padding-left: 50px !important; }

/*************************
    page-section
*************************/
.page-section-ptb {	padding: 50px 0;}
.page-section-pt {	padding: 50px 0 0;}
.page-section-pb { padding: 0 0 50px; }
.page-section-1-ptb { padding: 60px 0; }
.h-md-500 {height: 500px !important;}
.row-eq-height {  display: block;}
h2 {font-size: 25px; line-height: 35px;}
h3 { font-size: 20px; }
h4 {font-size: 16px;}
h5 {font-size: 14px;}

/*home-1*/
.navbar .collapse { display: none; }
.navbar .collapse.show { display: block; }
.navbar .navbar-toggler { border:0; }
.navbar .hamburger-box { padding: 0; display: inline-block; width:20px; height:20px; vertical-align: middle; }
.navbar .hamburger-inner { position: relative; display: block; font-size: 20px; }
.navbar .hamburger-inner:before { content: "\f0c9"; position: absolute; font-weight: 100; font-family: "FontAwesome"; top: 0; right: 0; }
.navbar .navbar-nav .nav-link { margin: 0; }
.navbar-left,.navbar-right {  float: none !important; }
.navbar-toggle { display: block; margin-right: 0; }
.navbar-collapse { border-top: 1px solid transparent; box-shadow: inset 0 1px 0 rgba(255,255,255,0.1); padding-left: 22px; }
.navbar-fixed-top { top: 0; border-width: 0 0 1px; }
.navbar-nav { float: none!important; margin-top: 7.5px; }
.navbar-nav>li { float: none; }
.navbar-nav>li>a { padding-top: 10px; padding-bottom: 10px; }
.collapse.in { display:block !important; }
.navbar .navbar-brand { padding:0;}
.navbar .navbar-brand img { height: 30px; }
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form { background: #323232; border:0; position: absolute; width: 100%; left: 0; right: 0; }
.navbar-collapse { box-shadow: none; }
.navbar-inverse .navbar-nav>li { padding: 15px 15px; }
.navbar-nav { float: none; }
.navbar-inverse .navbar-toggle { border:0; }
.navbar-toggle { margin-top: 14px; }
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover { background: transparent; }
#navbar_main { float: none; position: absolute; z-index: 999; background: #fff; padding: 0 20px 20px; width: 100%; max-height: 400px; overflow: auto; left: 0; margin-top: 0;  top: 56px; }
.navbar .navbar-nav .nav-link i{ float: right;  position: absolute; right: 0px; top: 0; bottom: 0; line-height: 42px; z-index: -1; }
.header.dark-topbar .navbar .navbar-nav .nav-link{ color: #626262; }
.navbar .nav-title { display: none; }
.navbar .navbar-nav img { display: none; }
.navbar-nav .dropdown-menu .blog-card{ display: none; }
.navbar .megamenu .dropdown-menu{ padding: 20px;  }

/*header default*/
.topbar .topbar-left { padding-bottom: 0; }
.header.default { position: relative; width: 100%; z-index: 999; background: #323232; }
.topbar ul li, .topbar ul li a { display: inline-block; color: #626262; }
.header.default .navbar .navbar-nav .nav-link { color: #626262; }
.header.fancy-topbar .topbar ul li, .topbar ul li a { display: inline-block; color: #fff; }
.header.light-topbar .topbar ul li, .topbar ul li a { display: inline-block; color: #626262; }
.header.dark-topbar .topbar ul li, .topbar ul li a{ color: #ffffff;  }

/*dark topbar*/
.header.dark-topbar, .header.default .topbar ul li, .topbar ul li a{ color: #ffffff;  }

/*.page-title*/
.page-title { padding-top: 100px; text-align: left; }
.page-title .page-breadcrumb { float: none; text-align: left; padding-left: 0px; padding-top:20px;}
.page-title.right .page-title-name { text-align: right; float: none; width: 100%; }
.page-title.right .page-breadcrumb { float: none; text-align: right; }
.portfolio-title.section-title { margin-bottom: 0; }
.happy-clients  { padding-bottom: 60px !important; }
.popup-video { margin-bottom: 30px; }
.custom-content { padding: 30px; margin-top: 30px; }
.custom-content-3-image { display: none; }
.happy-clients .col-md-6.mt-6 { margin-top: 0 !important; }

/*inner-intro*/
.inner-intro { padding-top: 120px; }

/*page two sidebar*/
.page-two-sidebar .page-content { margin-top: 40px; }

/*blog*/
.blog-box { margin: 15px 0;  padding: 20px;}

/*clients-logo*/
.clients-logo.line { width: 32.3333%; float: none; display: inline-block; }
.clients-logo.line img { width: 100%; }

/*contact*/
.contact.contact-2 a.button { margin-bottom: 30px; }
.contact-3 .contact-3-info { height: auto; }
.contact-3 .contact-map iframe { height: 400px; }
.contact-3 .map-side {  height: auto; position:inherit; }
.error-block { background-size: 90%;}
.error-block p { font-size: 26px; }
.error-search-box p { font-size: 19px; line-height: 35px; }
.map-side{position:inherit;}
.action-box.w-100 a.button {  right: 0px; }

/*action-box*/
.action-box.border,
.action-box.bg-theme,
.action-box.bg-dark,
.action-box.bg-gray,
.action-box.parallax,
.action-box.pattern,
.action-box.samll,
.action-box.big{ padding: 30px }

/*home 6 */
.our-activities .accordion { margin-top: 40px; }

/*maintenance*/
.maintenance-main h1.mb-5 { font-size: 34px; margin-bottom: 10px !important; }
.maintenance-main i { font-size: 60px; }
.maintenance-form { width: 100%; }
.maintenance-progress-bar span { font-size: 24px; }
.feature-text.box-shadow,
.special-feature .feature-text { padding:25px 15px; }
.feature-text.box,
.feature-text.fill-box {padding: 20px 10px;}
.feature-text.box p {font-size: 14px;}
.feature-box-content { padding: 20px 15px 0;}
.feature-box a {padding: 0 15px;}
.progress-title {font-size: 15px;}
.section-title {	margin-bottom: 30px;}
.section-title p{ font-size: 16px; }
.counter .timer {font-size: 38px;}
.counter label {font-size: 14px;}
.team-3-box .team-overlay {padding: 30px 15px 0;}
.team-3-box {padding: 10px;}
.menu-bar {float: none;}
.mega-menu .menu-links > li > a{line-height: normal;}
.mega-menu .menu-mobile-collapse-trigger {height: 40px; top: 50%; transform: translateY(-50%); z-index: 9999; }
.mega-menu .menu-links > li > a{ padding:10px 12px; }
.search-box{ right: 5px; }
.search-cart { right: 50px; top: -6px; z-index: 999; }
.search-active .search-cart  { left: 0; width: 100%; }
.search .search-input input { width: 100%; }
.search-active .mega-menu .menu-mobile-collapse-trigger { z-index: 9; }
.search-cart .search { display: inline; }
.header.fancy .search-cart{ position: absolute; top: -3px; }
.header.fancy .search-box { margin-top: 0; }
.footer p {font-size: 13px;}
.footer-logo img{ height: 28px !important; }
.feature-text p{font-size: 14px;}
.accordion.accordion-2 .acd-group.acd-active .acd-heading {font-size: 22px;}
.split-section .img-side{display: none;}
.our-service { padding-bottom: 60px;}
.testimonial.boxed {padding: 50px 30px 75px;}
.button {font-size: 12px; padding: 12px 15px;}
.feature-step-2 .feature-step-2-title-left h1 {font-size: 80px;}
.services-text-box-blue,
.services-text-box-black {padding: 50px 30px;}
.highlights-phone {margin-top: 20px;}
.mobile-slider h5{font-size: 14px;}
.service-blog b{right: 0; font-size: 250px;}
.service-blog.left b{left: 0;}

/*blog pages*/
.blog.blog-grid-3-column .social.pull-right { float: none; margin-top: 10px; display: block; width: 100%; }
.masonry.columns-3 .masonry-item { width: 50%; }
.blog.timeline-sidebar .timeline li.timeline-inverted .timeline-badge { left: -50px; }

/*portfolio*/
.isotope-filters { margin-bottom: 40px; }
.isotope.columns-3 .grid-item { width: 50%; }
.isotope.columns-4 .grid-item { width: 50%; }
.isotope.columns-5 .grid-item { width: 50%; }
.masonry.columns-3 .masonry-item { width: 50%; }
.masonry.columns-4 .masonry-item { width: 50%; }
.masonry.columns-5 .masonry-item { width: 50%; }
.port-post-social.pull-right { float: none !important; }

/*process*/
.process.left .process-content { padding-left: 50px; }

/*resume page*/
img.bottom-img { display: none; }
.resume-contact #map { height: 300px !important; }
.contact-4 .map-side { height: 300px !important; }

/*footer*/
img#logo-footer { height: 30px; }
.bottom-nav .owl-nav .owl-next { right: 40%; }
.bottom-nav .owl-nav .owl-prev { left: 40%; }

/*blockquote*/
.blockquote-right { margin: 0; }

/*buttons*/
.buttons .button { margin-bottom: 10px; }
.buttons button { margin-bottom: 4px; }
.button + .button { margin-left: 3px; }

/*countdown*/
.countdown { margin: 0px 10px;  }
.countdown.medium {  margin: 0 10px; }
.countdown.large { margin: 0 10px; }
.counter label{ font-size: 12px; }
.countdown.round.medium { width: 138px; height: 138px; }
.countdown.medium span { font-size: 40px; line-height: 40px; }
.countdown.round.medium span { line-height: 50px; font-size: 40px; }
.countdown.round.large { width: 148px; height: 148px; }
.countdown.large span { font-size: 56px; }
.countdown.round.large span { line-height: 62px; }
.countdown.large p { font-size: 18px; }
.vertical-menu .social-icons li { margin: 0 3px 8px 0; }

/*actionbox*/
.action-box a.button { position: relative; top: inherit; margin-top: 20px; }
.action-box.bg-theme a.button, .action-box.bg-dark a.button, .action-box.bg-gray a.button { right: inherit; }
.action-box.pattern a.button, .action-box.bg-dark a.button, .action-box.bg-gray a.button { right: inherit; }
.agency-about { padding-bottom: 60px !important; }
.vertical-header .container-fluid { padding: 0 15px; }
.resume-contact .contact-form-main { padding: 30px 15px; }

/*pricing-table*/
.pricing-table.boxed {padding: 20px 10px;}
.membership-pricing-table table tr:first-child th .pricing-plan-price{ line-height: 30px; font-size: 22px;}

/*construction*/
.our-sercive1:before, .our-sercive1:after { display: none; }
.special-feature { top: 60px; margin-bottom: 60px; }
.page-section-1-pt { padding-top: 70px; }
.page-section-1-pb { padding-bottom: 70px; }
.page-section-1-ptb { padding: 70px 0; }

/*one page*/
.popup-video-banner h1 { font-size: 40px; line-height: 40px; }
.video-background-banner .slider-content { width: 100%; }
.video-background-banner h1 { font-size: 60px; line-height: 60px; }

/*shop*/
.shop-split.split-section { padding: 0px; }
.shop-split-content { padding: 40px 0; }
.shop-split.split-section .img-side  { display: block; }
.shop-split.split-section .img-holder { position: relative; top: inherit; width: inherit; height: inherit; padding: 130px; }
.shop-split.split-section .img-side.img-right { right: inherit; }
.shop-split.split-section .img-side.img-left { left: inherit; }
.shop-split.split-section .img-side { right: inherit; padding: 0; position: relative !important;  top: inherit; height: 100%; overflow: hidden; z-index: 100; }
.add-banner-2 .add-banner-content { padding: 60px 20px; }
.add-banner-2 .add-banner-content h2 {font-size: 28px;line-height: 28px; }
.add-banner-2 .add-banner-content h3 {font-size: 26px; line-height: 26px; }
.add-banner-2.text-center .add-banner-content h2 {font-size: 26px;line-height: 29px; }

/*portfolio-banner*/
.portfolio-banner .slider-content h1 { font-size: 30px; line-height: 30px;  }
.personal-typer-banner h1 { font-size: 70px; line-height: 70px; }
.personal-typer-banner h1 span { font-size: 35px; }
.deal-banner.maintenance-progress-bar img { width: 100%; }

/*404 error*/
.page-section-ptb.mt-15 { margin-top: 0 !important; }

/*banner*/
#main-slider .heading-extra-large{ font-size: 42px; line-height:48px; }
#main-slider .heading-large { font-size: 48px;  line-height: 56px; }
#main-slider .heading-small { font-size: 18px; line-height: 26px; }
#main-slider .heading-med { font-size: 24px; line-height: 30px;}
#main-slider .heading-underline:after{ bottom: 10px;  }
#main-slider .heading-dots li{  padding-left: 20px; margin-left: 10px;}
#main-slider .heading-dots li:after{ width: 10px; height: 10px; }
#main-slider.carwash .heading-large { font-size: 26px;line-height: 28px;}
#main-slider.carwash .heading-med { font-size: 24px;line-height: 30px;}
#main-slider.carwash .heading-small{ font-size: 14px; line-height: 22px;  }

/*testimonial*/
.testimonial p{ font-size: 18px;  }
.contact-3 #map { padding-top: 30%; }
.contact-2 #map { padding-top: 30%; }
.hidden-sm { display: none; }
.team .team-contact span{ font-size: 13px;  }
.team .social-icons li { margin: 0 4px 4px 0; }
.tab .nav.nav-tabs li a{ padding: 12px 20px; }
.tab .nav.nav-tabs{ margin-bottom: 15px; }
.error-block .error-text h2{ font-size: 300px;}
.error-block .error-text span{ font-size: 80px; top: 110px; }
.error-text-02 h2{ font-size: 300px; left: -30px;  }
.error-text-02 span{ font-size: 70px; right: -40px; top: 123px; }
.sidebar-widget{ margin-bottom: 40px; }

/*our-history .timeline*/
.our-history .timeline > li.timeline-inverted > .timeline-panel:before{left: -15px}
.our-history .timeline > li > .timeline-panel:before {  right: -15px;}
.google-map .map-icon:before{ width: 50px; height: 50px; line-height: 50px; top: -24px; font-size: 24px; }

/*new-bs-4*/
.btn { padding: 8px 15px;}
.btn.btn-lg{ padding: 8px 24px; font-size: 16px; }
.btn.btn-xl{ padding: 10px 30px; font-size: 20px; }
.special-feature .card .card-body{ padding: 30px; }
.accordion-style-1 .card-header a,
.accordion-style-2 .card-header a,
.accordion-style-3 .card-header a { padding: 10px 30px; }
.accordion-icon.card-header a::before { left: 12px; }
.team-card .card-body ul { padding-left: 5px; }
.play-video { width: 80px; height: 80px; }
.play-video a i { line-height: 80px; font-size: 25px; }
.nav.nav-tabs li a { margin-right: 5px; margin-bottom: 0px;}

/*index-03 Mechanic*/
#main-slider.mechanic .heading-extra-large { font-size: 50px; line-height: 55px;}

/*index-04 Gardener*/
#main-slider.gardener .heading-large { font-size: 50px; line-height: 60px;}
#main-slider.gardener .btn.btn-lg { margin-top: 4px; padding: 10px 14px; font-size: 12px; letter-spacing: 2px; line-height: 10px;}

/*index-05 Moving*/
#main-slider.moving .border-l:before { position: absolute; content: ""; left: 0; top: 9px; width: 5px; height: 93px; background: #d12326;}
#main-slider.moving .border-r:before { position: absolute; content: ""; right: 0; top: 9px; width: 5px; height: 93px; background: #d12326;}
#main-slider.moving .heading-large { font-size: 48px;  line-height: 56px;}

/*index-06 Cleaning*/
.navbar .navbar-toggler{ padding: 0; }
#main-slider.cleaning .border-rotate:after{ height: 50px; }
#main-slider.cleaning .border-rotate{ bottom: 40px;  }
#main-slider.cleaning .rotate{ margin: 10px 0 0 0 !important; }
#main-slider.cleaning .heading-small{ font-size: 14px; line-height: 20px;  }

/*index-07 Car-wash*/
#main-slider.carwash .heading-med {font-size: 24px; line-height: 24px;}
#main-slider.carwash .carousel-indicators{display: none;}

/*index-08 Electrician*/
#main-slider.electrician .slider-content p{font-size: 16px;line-height: 18px;}
.home-08{padding: 100px 0px;}
.home-08 h1{font-size: 48px;}


/*index-09 Carpenter*/
#main-slider.carpenter .heading-med { font-size: 24px; line-height: 30px;}
#main-slider.carpenter .heading-large { font-size: 40px; line-height: 56px;}
#main-slider.carpenter .btn.btn-lg { padding: 8px 20px; line-height: 25px; font-size: 14px; }

/*index-10 Welder*/
.hover-overlay .icon-circle { width: 35px; height: 35px;line-height: 35px; font-size: 14px;}
.hover-overlay .icon-square {width: 40px;  height: 40px; border: 1px solid rgba(255,255,255,.7);line-height: 40px; color: #fff; display: inline-block; margin: 10px 5px; font-size: 16px;}
#main-slider.welder .swiper-slide h1 { font-size: 42px; line-height: 48px;}
#main-slider.welder .swiper-slide h3 { font-size: 24px; line-height: 30px;}
#main-slider.welder ul li i {padding-right: 10px;}
#main-slider.welder ul li {font-size: 18px; line-height: 26px; margin-right: 15px;}

/*Cleaning*/
.cleaning-bg { background: none !important;}

}

@media(max-width:767px) {

/*************************
     Margin top
*************************/
.sm-mt-0 { margin-top: 0 !important; }
.sm-mt-1 { margin-top: 10px !important; }
.sm-mt-2 { margin-top: 20px !important; }
.sm-mt-3 { margin-top: 30px !important; }
.sm-mt-4 { margin-top: 40px !important; }
.sm-mt-5 { margin-top: 50px !important; }

/*************************
    Margin right
*************************/
.sm-mr-0 { margin-right: 0!important }
.sm-mr-1 { margin-right: 10px !important; }
.sm-mr-2 { margin-right: 20px !important; }
.sm-mr-3 { margin-right: 30px !important; }
.sm-mr-4 { margin-right: 40px !important; }
.sm-mr-5 { margin-right: 50px !important; }

/*************************
    Margin bottom
*************************/
.sm-mb-0 { margin-bottom: 0!important }
.sm-mb-1 { margin-bottom: 10px !important; }
.sm-mb-2 { margin-bottom: 20px !important; }
.sm-mb-3 { margin-bottom: 30px !important; }
.sm-mb-4 { margin-bottom: 40px !important; }
.sm-mb-5 { margin-bottom: 50px !important; }

/*************************
    Margin left
*************************/
.sm-ml-0 { margin-left: 0!important }
.sm-ml-1 { margin-left: 10px !important; }
.sm-ml-2 { margin-left: 20px !important; }
.sm-ml-3 { margin-left: 30px !important; }
.sm-ml-4 { margin-left: 40px !important; }
.sm-ml-5 { margin-left: 50px !important; }

/*************************
    Padding top
*************************/
.sm-pt-0 { padding-top: 0!important }
.sm-pt-1 { padding-top: 10px !important; }
.sm-pt-2 { padding-top: 20px !important; }
.sm-pt-3 { padding-top: 30px !important; }

/*************************
    Padding right
*************************/
.sm-pr-0 { padding-right: 0!important }
.sm-pr-1 { padding-right: 10px !important; }
.sm-pr-2 { padding-right: 20px !important; }
.sm-pr-3 { padding-right: 30px !important; }

/*************************
    Padding bottom
*************************/
.sm-pb-0 { padding-bottom: 0!important }
.sm-pb-1 { padding-bottom: 10px !important; }
.sm-pb-2 { padding-bottom: 20px !important; }
.sm-pb-3 { padding-bottom: 30px !important; }

/*************************
    Padding left
*************************/
.sm-pl-0 { padding-left: 0!important }
.sm-pl-1 { padding-left: 10px !important; }
.sm-pl-2 { padding-left: 20px !important; }
.sm-pl-3 { padding-left: 30px !important; }
h1 { font-size: 30px; }
h2 { font-size: 26px; }
.section-title {  margin-bottom: 20px;}
.h-sm-400 {height: 400px !important;}
.header.fullWidth .container-fluid { padding: 0 20px; }

/*header topbar*/
.topbar-call.text-left { text-align: center; margin-bottom: 10px; }
.topbar-social.text-right { text-align: center; }

/*nice select*/
.nice-select .list{ max-height: 180px; }

/*team*/
.team-3-box img { width: 100%; }

/*accordian*/
.accordion.accordion-2 .acd-group .acd-heading { padding-left: 0px; padding-right: 0px;  }

/*faq*/
.nav-tabs li a{ font-size: 10px; }
.tab .nav.nav-tabs li a{ padding: 10px; margin-right: 2px; }

/*footer*/
.footer-logo img { height: 60px; width: inherit; }
img#logo-footer { height: 40px; }
.footer .footer-nav.text-right { text-align: left; margin-top: 20px; }
.social.text-right { text-align: left; margin-top: 20px; }
.footer-contact { margin-top: 0; box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1); }
.our-clients.bg-theme { padding-bottom: 60px; }
.footer-contact .contact-box  { margin-bottom: 20px; }
.copyright.mt-5 { margin-top: 10px !important; }
.copyright .text-right { text-align: left; margin-top: 15px; }
footer .social-icons.pull-right { float: left !important; width: 100%; }
.footer-widget.mt-6 { margin-top: 0 !important; }
.footer-box { margin-bottom: 20px; margin-top: 30px; }
.footer-Newsletter .button.border{ padding: 6px; }

/* Coming soon page */
.coming-soon-main { overflow: hidden; }
.coming-soon-main i { font-size: 70px; }
.coming-soon-countdown ul.countdown li span { font-size: 60px; }
.coming-soon-countdown ul.countdown li p { font-size: 16px; }
.coming-soon-main p { font-size: 18px; line-height: 26px; }
.coming-soon-main h1 { font-size: 42px; margin-bottom: 20px; }

/*error*/
.error-search-box input { width: 73%;  }
.error-block p { font-size: 20px; }
.error-block h1 { font-size: 80px; }
.error-404-2 .error-block h1 { font-size: 290px; }
.maintenance-progress-bar .progress-bar { display: block; margin: 0 auto 90px; }
.maintenance-progress-bar { margin: 30px 0 60px; }
.maintenance-main p { margin-bottom: 10px; }

/*portfolio*/
.isotope.columns-3 .grid-item { width: 50%; }
.isotope.columns-4 .grid-item { width: 50%; }
.isotope-filters { margin-bottom: 40px; }
.isotope-filters  button{font-size: 14px;}

/*============== New ==============*/
/*about-1 page*/
.about .feature-3 { margin-bottom: 30px; }
.feature-text.box, .feature-text.fill-box{ padding: 30px;}
.footer-widget { text-align: left; }
.xs-text-left{text-align: left;}
.xs-text-center{text-align: center;}
.xs-text-right{text-align: right;}
.pricing-table{margin: 0 0 30px;}
.lead {font-size: 14px;}
.inner-intro { text-align: center;}
ul.page-breadcrumb { padding-top: 30px;}
.feature-step-2-box {padding: 20px;}
.feature-step-2 .feature-step-2-title-left h1 {font-size: 50px;}
.our-app{background-image: none !important;}
.valign > [class*="col-"] {display: block;}
.highlights-phone {margin: 30px 0;}

/*blog pages*/
.masonry.columns-2 .masonry-item { width: 100%; }
.masonry.columns-3 .masonry-item { width: 100%; }

/* blog timeline */
.blog ul.timeline:before { left: 40px; }
.blog ul.timeline > li { margin-bottom: 20px; position: relative; width:100%; float: left; clear: left; }
.blog ul.timeline > li > .timeline-panel { width: calc(100% - 90px); width: -moz-calc(100% - 90px); width: -webkit-calc(100% - 90px); }
.blog ul.timeline > li > .timeline-badge { left: 12px; margin-left: 0; top: 16px; }
.blog ul.timeline > li > .timeline-panel { float: right; }
.blog ul.timeline > li > .timeline-panel:before { border-left-width: 0; border-right-width: 15px; left: -15px; right: auto; }
.blog ul.timeline > li > .timeline-panel:after { border-left-width: 0; border-right-width: 14px; left: -14px; right: auto;  }
.blog .timeline > li.timeline-inverted { float: left; clear: left; margin-top: 30px; margin-bottom: 30px; }
.blog .timeline > li.timeline-inverted > .timeline-badge { left: 12px; }
.blog .timeline-panel img { width: 100%; }
.blog .timeline li.entry-date-bottom { text-align: left; }
.blog .timeline li.entry-date-bottom a { font-size: 13px; padding: 8px 10px; }
.blog .timeline li.entry-date { text-align: left; }
.blog .timeline li.entry-date span {  font-size: 13px; padding: 8px 10px;  }
.blog.timeline-sidebar .timeline li.timeline-inverted .timeline-badge { left: 23px; }
.blog .timeline > li:nth-child(2) { margin-top: 0; }
.blog .timeline > li.timeline-inverted { margin-top: 10px; }
.blog.timeline-sidebar .timeline > li.timeline-inverted { margin-bottom: 20px; }
.blog.timeline-sidebar .timeline > li.timeline-inverted:nth-child(2) { margin-bottom: 0px; }
.blog.timeline-sidebar .timeline li .blog-entry-grid .grid-post li { width: 100%; }
.blog-box.blog-2 .blog-info{ display: block; }
.blog-info p{ font-size: 14px; }
.blog-box{ margin: 30px 0; }

/*login 2*/
.login-2-social li { margin-bottom: 10px; }

/*maintenance*/
.progress-new { width: 100%; }
.maintenance-form input { width: 100%; }

/*owl-carousel*/
.owl-carousel .owl-item img{ width: 100%; }

/*portfolio*/
.isotope.columns-2 .grid-item { width: 100%; }
.isotope.columns-3 .grid-item { width: 100%; }
.isotope.columns-4 .grid-item { width: 100%; }
.isotope.columns-5 .grid-item { width: 100%; }
.masonry.columns-3 .masonry-item { width:100%; }
.masonry.columns-4 .masonry-item { width:100%; }
.masonry.columns-5 .masonry-item { width:100%; }

/*shop*/
.deal-banner img { width: 100%; }

/*process*/
.process.left .process-content .process-icon { display: block; margin-bottom: 20px; }
.process.left .process-content { padding-left: 40px; }
.process.right .process-content { padding-right: 40px; }
.process.right .process-content .process-icon { float: none; margin-bottom: 20px; }
.process.left .process-step { top: 30px; }
.process .process-step strong { width: 40px; height: 40px; line-height: 40px; }
.process.left .process-step { left: -20px; }
.process.right .process-step { right: -20px; }

/*resume*/
.resume-page .container-fluid { padding: 0 15px; }

/*shortcode*/
.clients-list ul li, .clients-list.column-4 ul li, .clients-list.column-3 ul li { width: 50%; }

/*countdown*/
.countdown.medium span { font-size: 30px; line-height: 30px; }
.countdown.medium p { font-size: 14px; }
.countdown.round.medium { display: inline-block; margin: 10px auto; width: 100px; height: 100px; }
.countdown.round.medium span { font-size: 30px; line-height: 30px; }
.countdown.round.medium p { font-size: 14px; }

/*datatables*/
.dataTables_paginate .pagination>li  { display: inline-block; text-align: center; }
.datatable-base .table-responsive { border: 0; padding-bottom: 30px; }

/*feature text*/
.feature-text.left-icon .feature-icon { padding-left: 15px; }
.feature-text.right-icon .feature-icon { padding-right: 15px; }
/*tab*/
.tab-vertical .nav-tabs { width: 100%; }
.tab-vertical .tab-content { width: 100%; margin-left: 0; }
.feature-text .feature-icon span, .feature-text .feature-icon i { padding-left: 0; }
.bg-top-before  { padding-top: 40px !important; }
.bg-top-before:before { display: none; }
.bg-top-before.pb-15 { padding-bottom: 40px !important; }

/*product*/
.product .product-image img { width: 100%; }

/*portfolio-banner*/
.portfolio-banner .slider-content h1 { font-size: 20px; line-height: 20px; }
.portfolio-banner .slider-content span { font-size: 12px; line-height: 18px; }

/*404 error*/
.error-block  { padding: 0; }
.error-block .error-text { padding: 0; display: none; }
.error-block h1 { padding: 0; }
.error-text-02 { display: none; }

/*banner*/
 #main-slider .heading-extra-large{ font-size: 36px; line-height: 38px; }
 #main-slider .heading-large { font-size: 38px;  line-height: 42px; letter-spacing: 1px; }
 #main-slider .heading-small { font-size: 16px; line-height: 20px; }
 #main-slider .heading-med { font-size: 22px; line-height: 26px;}
 #main-slider .heading-underline:after{ bottom: 10px;  }
 #main-slider .heading-dots li{  padding-left: 20px; margin-left: 10px;}
 #main-slider .heading-dots li:after{ width: 10px; height: 10px; }
 #main-slider .carousel-control.left{ left: -25px; }
 #main-slider .carousel-control.right{ right: -25px; }
.banner-patarn {height: 60px; margin-top: -6%;}

/*new-bs-4*/
.btn { padding: 8px 12px; }
.btn.btn-lg{ padding: 8px 20px; font-size: 14px; }
.btn.btn-xl{ padding: 10px 24px; font-size: 18px; }
#main-slider.electrician .carousel-indicators {display: none;}
.section-title .subtitle { font-size: 12px;}

/*index-03 Mechanic*/
#main-slider.mechanic .heading-extra-large {font-size: 44px; line-height: 50px;}

/*index-04 Gardener*/
#main-slider.gardener .heading-large { font-size: 40px; line-height: 50px; margin-top: 40px;}
#main-slider.gardener .btn.btn-lg { margin-top: 4px; padding: 7px 12px; font-size: 10px; letter-spacing: 1px; line-height: 10px;}

/*index-05 Moving*/
#main-slider.moving .border-l:before {left: 0; top: 5px;height: 68px;}
#main-slider.moving .border-r:before {right: 0; top: 5px;height: 68px;}
#main-slider.moving .heading-large { font-size: 35px; line-height: 40px; }

/*index-06 Cleaning*/
#main-slider.cleaning .rotate{ margin: 5px 0 0 0 !important; }

/*index-07 Car-wash*/
#main-slider.carwash .heading-large {font-size: 40px; line-height: 40px;  }
#main-slider.carwash .heading-med { font-size: 16px;line-height: 26px;}
#main-slider .carousel-indicators {display: none;}
#main-slider.carwash .heading-small{ font-size: 12px; line-height: 16px; }
#main-slider.carwash .swipeinner .card{ display: none; }

/*index-08 Electrician*/
#main-slider.electrician.slider-content p{font-size: 16px;}

/*index-09 Carpenter*/
#main-slider.carpenter .heading-med { font-size: 14px; line-height: 18px;}
#main-slider.carpenter .heading-large { font-size: 30px; line-height: 34px; margin-bottom: 15px;}
#main-slider.carpenter .btn.btn-lg { padding: 8px 12px; font-size: 10px; line-height: 14px; }

/*index-10 Welder*/
#main-slider.welder .swiper-slide h1 { font-size: 36px; line-height: 38px; margin-bottom: 15px;}
#main-slider.welder .swiper-slide h3 { font-size: 22px; line-height: 26px;}
#main-slider.welder ul li { font-size: 16px; line-height: 24px; margin-right: 10px;}
#main-slider.welder .animated{margin-top: 0;}

}

@media(max-width:600px) {
h3 { font-size: 22px; }
.portfolio-home .isotope.columns-3 .grid-item { width: 100%; }
.portfolio-title { padding: 40px 30px; }
.main-counter .counter-info span.counter { font-size: 40px; }

/*action-box*/
.action-box { text-align: center; }
.action-box .action-box-button { text-align: center; }

/*skills*/
.skills-2.col-2 li { width: 100%; margin-bottom: 0; margin-right: 0; float: none; }
.skills-2.col-2.mr-6 { margin-right: 0 !important; }

/*error*/
.error-block { padding: 30px 0px; }
.error-block h1 { font-size: 70px; }
.error-404-2 .error-block h1 { font-size: 200px; line-height: 200px; }
.error-404-2 .error-block p { font-size: 16px; }

/*maintenance*/
.maintenance-main h1.mb-5 { font-size: 28px; }
.maintenance-2 .maintenance-main h1 { font-size: 32px; }

/*portfolio*/
.isotope.columns-3 .grid-item { width: 100%; }
.isotope.columns-4 .grid-item { width: 100%; }
.isotope-filters { margin-bottom: 20px; display: none;}

/*about-1 page*/
.clients-logo.line img{height:65px;}
.our-history .timeline:before { left: 40px; }

/*contact us*/
.contact-form .section-field { width: 100%; }
.touch-in .contact-box { margin: 20px 0; }

/*our-history .timeline*/
.our-history .timeline > li.timeline-inverted > .timeline-panel::before,
.our-history .timeline > li > .timeline-panel::before {  left: 50px;}
.our-history .timeline > li > .timeline-panel {  padding: 40px 20px 20px 75px;  text-align: left;  width: 100%;}
.our-history .timeline > li > .timeline-badge {  left: 41px;}
.our-history .timeline > li > .timeline-badge p {  margin-left: 40px;}
.our-history .timeline > li.timeline-inverted .timeline-badge p {  float: left;  margin-right: 0;  text-align: left;}
.feature-text .feature-icon {font-size: 30px;}
.skill-bar {margin: 60px 0 20px;}
.feature-text.box {padding: 30px;}
.happy-clients .nav-tabs li img {height: 70px; width: 70px;}
.testimonial-info p {font-size: 15px; margin: 25px 0 0; padding: 0 0 20px;}
.testimonial-2 .testimonial-info p::before {font-size: 100px;}

/*blog pages*/
.social.pull-right { float: none; margin-top: 0px; display: block; width: 100%; }
.port-post-social.pull-right { float: none !important; }
.port-post-photo { display: block; float: none; }
.port-post-info { padding-left: 0; margin-top: 20px; display: block; }
.comments-1.comments-2 { padding-left: 40px; }
.comments-1 .comments-photo { float: none; }
.comments-1.comments-2 .comments-info { padding: 20px 20px 10px; }

/*portfolio*/
.isotope.columns-1 .grid-item { padding: 10px 0; }
.isotope.columns-2 .grid-item { padding: 10px 0; }
.isotope.columns-3 .grid-item { padding: 10px 0; }
.isotope.columns-4 .grid-item { padding: 10px 0; }

/*team single*/
.our-team .team-details .social { float: none !important; width: 100%; margin-bottom: 20px; }

/*shortcode*/
.countdown.medium span { display: block; font-size: 30px; line-height: 30px }
.countdown.medium p { font-size: 14px; }
.countdown.medium { margin: 0 1px; padding: 8px; }
.countdown.large span  { display: block; font-size: 30px; }
.countdown.large p { font-size: 14px; }
.countdown.large { margin: 0 10px; }
.countdown.bg-gray { margin: 0 1px; padding: 8px; }

/*testimonial*/
.testimonial.bottom_pos .author-info { margin-left: 0; margin-bottom: -12px; }

/*shortcode*/
.pagination>li { display: inline-block; margin-bottom: 3px; }

/*shortcode*/
.owl-carousel .owl-dots { bottom: -15; }
.resume-contact .footer-widget-social a i { margin-bottom: 4px; }

/*popup-video-banner*/
.popup-video-banner h1 { font-size: 36px; line-height: 40px; }
.kety-perry-list { padding-right: 20px; }
.kety-play-icon { float: none; }
.kety-name { padding-left: 0; }
.kety-play-icon { margin-top: 0; }

/*team*/
.team.team-list .team-photo { float: none; width: 100%; margin-bottom: 30px; }
.team.team-list .team-description { padding-left: 0; padding-bottom: 0; }
.tab .nav.nav-tabs li { margin-bottom: 5px; }

/*testimonial*/
.testimonial.clean { padding: 0 10px 25px 40px; }
.typer-banner h1 { font-size: 50px; line-height: 100px; }
.portfolio-banner .slider-content { width: 100%; }
.personal-typer-banner h1 { font-size: 50px; line-height: 50px; }
.personal-typer-banner h1 span { font-size: 25px; }

/*banner*/
 #main-slider .heading-extra-large{ font-size: 28px; line-height: 34px; }
 #main-slider .heading-large { font-size: 28px;  line-height: 30px; }
 #main-slider .heading-small { font-size: 14px; line-height: 16px;  }
 #main-slider .heading-med { font-size: 18px; line-height: 22px;}
 #main-slider .heading-dots li{  padding-left: 20px; margin-left: 10px;}
 #main-slider .heading-dots li:after{ width: 10px; height: 10px; }

/*swiper-button*/
#main-slider .swiper-button-prev{display: none;}
#main-slider .swiper-button-next{display: none;}

/*swiper-pagination*/
#main-slider .swiper-pagination .swiper-pagination-bullet{display: none;}
.banner-patarn {height: 50px; margin-top: -6%;}
.button {   font-size: 11px;   padding: 6px 8px; }
.our-history .timeline .timeline-arrow { left: 34px; text-align: left; }
.btn { padding: 5px 10px;}
.btn-outline-light { height:38px; }
.slider-content h1{ margin-bottom: 0px;  }
.menu-bg { background-image: none; }

/*index-02 Renovation*/
#main-slider.renovation-banner .heading-extra-large { font-size: 24px;line-height: 24px;}
#main-slider.renovation-banner .heading-med { font-size: 14px;line-height: 16px;}
#main-slider.renovation-banner .carousel-control.left{top:50%;}
#main-slider.renovation-banner .carousel-control.right{top: 50%;}

/*index-03 Mechanic*/
#main-slider.mechanic .heading-extra-large { font-size: 40px; line-height: 50px;}
#main-slider.mechanic .carousel-control.right { right: 0; }

/*index-04 Gardener*/
#main-slider.gardener .heading-large { font-size: 30px; line-height: 40px;}
#main-slider.gardener .btn.btn-lg { margin-top: 4px; padding: 6px 10px; font-size: 8px; letter-spacing: 1px; line-height: 10px;}

/*index-06 Cleaning*/
#main-slider.cleaning .border-rotate{     bottom: 30px; }
#main-slider.cleaning .rotate{ font-size: 12px; line-height: 16px; }
#main-slider.cleaning .slider-left{ padding-right: 10px; }

/*index-07 Car-wash*/
#main-slider.carwash .heading-large {font-size: 35px; line-height: 24px;}
#main-slider.carwash .heading-med { font-size: 14px;line-height: 20px;}

/*index-08 Electrician*/
#main-slider.electrician .slider-content p{ font-size: 13px;line-height: 20px;}

/*index-09 Carpenter*/
#main-slider.carpenter .heading-large { font-size: 24px; line-height: 30px;}
#main-slider.carpenter .border-lr:before{ width: 60px; left: -80px;  }
#main-slider.carpenter .border-lr:after{  width: 60px; right: -80px; }

/*index-10 Welder*/
#main-slider.welder .swiper-slide h1 { font-size: 28px; line-height: 36px; margin-bottom: 15px;}
#main-slider.welder .swiper-slide h3 { font-size: 18px; line-height: 24px;}
#main-slider.welder ul li { font-size: 14px; line-height: 22px; margin-right: 10px;}
#main-slider.welder .btn{padding: 6px 14px; font-size: 12px; margin-top: 0 !important; line-height: 20px !important; }

}

@media(max-width:479px) {

/*************************
     Margin top
*************************/
.xx-mt-0 { margin-top: 0 !important; }
.xx-mt-1 { margin-top: 10px !important; }
.xx-mt-2 { margin-top: 20px !important; }
.xx-mt-3 { margin-top: 30px !important; }

/*************************
    Margin right
*************************/
.xx-mr-0 { margin-right: 0!important }
.xx-mr-1 { margin-right: 10px !important; }
.xx-mr-2 { margin-right: 20px !important; }
.xx-mr-3 { margin-right: 30px !important; }

/*************************
    Margin bottom
*************************/
.xx-mb-0 { margin-bottom: 0!important }
.xx-mb-1 { margin-bottom: 10px !important; }
.xx-mb-2 { margin-bottom: 20px !important; }
.xx-mb-3 { margin-bottom: 30px !important; }

/*************************
    Margin left
*************************/
.xx-ml-0 { margin-left: 0!important }
.xx-ml-1 { margin-left: 10px !important; }
.xx-ml-2 { margin-left: 20px !important; }
.xx-ml-3 { margin-left: 30px !important; }

/*************************
    Padding top
*************************/
.xx-pt-0 { padding-top: 0!important }
.xx-pt-1 { padding-top: 10px !important; }
.xx-pt-2 { padding-top: 20px !important; }
.xx-pt-3 { padding-top: 30px !important; }

/*************************
    Padding right
*************************/
.xx-pr-0 { padding-right: 0!important }
.xx-pr-1 { padding-right: 10px !important; }
.xx-pr-2 { padding-right: 20px !important; }
.xx-pr-3 { padding-right: 30px !important; }

/*************************
    Padding bottom
*************************/
.xx-pb-0 { padding-bottom: 0!important }
.xx-pb-1 { padding-bottom: 10px !important; }
.xx-pb-2 { padding-bottom: 20px !important; }
.xx-pb-3 { padding-bottom: 30px !important; }

/*************************
    Padding left
*************************/
.xx-pl-0 { padding-left: 0!important }
.xx-pl-1 { padding-left: 10px !important; }
.xx-pl-2 { padding-left: 20px !important; }
.xx-pl-3 { padding-left: 30px !important; }
.col-xx-12{width:100% !important;}
.col-xx-6{width:50% !important;}
h2 {font-size: 22px; line-height: 30px;}
.clients-logo.line img { width: initial; }
.isotope-filters { margin-bottom: 20px; }

/*action box*/
.action-box-button .button.border-white { margin-top: 10px; }
.action-box-button .button.border.icon { margin-left: 0; margin-top: 15px; }
.action-box-button .button.border { margin-left: 0; margin-top: 15px; }

/*cart*/
.shpping-cart .cart { width: 230px; }
.shpping-cart .cart-total a { margin-bottom: 10px; }

/*coming soon*/
.coming-soon-main h1 { font-size: 34px; }
.coming-soon-countdown ul.countdown li span { font-size: 32px; }
.coming-soon-countdown ul.countdown li { width: 20%; }
.coming-soon-countdown ul.countdown li p { font-size: 13px; }
.coming-soon-countdown ul.countdown { margin: 20px 0; }
.coming-soon-main p { margin-bottom: 45px; font-size: 16px; }
.coming-soon-form { width: 100%; }
.coming-soon-form input { width: 90%; margin-bottom: 0; }
.coming-soon-form a.button-border span { padding: 11px 8px; }

/*error*/
.error-block h1 { font-size: 50px; }
.error-block p { font-size: 18px; }
.error-block h1.mb-4 { margin-bottom: 10px !important; }
.error-search-box p { font-size: 16px; line-height: 32px; }
.error-search-box input { width: 60%; }

/*maintenance*/
.maintenance-2 .maintenance-main h1 { font-size: 22px; margin-bottom: 10px; }
.maintenance-contant.mt-15 { margin-top: 30px !important; }
.maintenance-form.mt-10 { margin-top: 30px !important; }
.clients-logo.line{width:100%; border-left:none;}
.clients-logo.line img{height:auto;}
.clients-logo.line:nth-last-child(-n+3) {  border-bottom: 1px solid #dddddd;}
.our-history .timeline > li > .timeline-panel::before { width: 22px; left: 50px; }
.our-history .timeline > li.timeline-inverted > .timeline-panel::before { right: 25px; }
.happy-clients .nav.nav-tabs li { display: inline-block; }
.nav.nav-tabs li {display: block; float: none; margin-bottom: 10;text-align: center;}
.tab-2 .nav-tabs {float: none; width: 100%;}
.tab-2 .nav-tabs li{margin-bottom: 0;}
.tab-2 .tab-content {margin-left: 0; padding: 30px 20px;}

/*testimonial page*/
.testimonial-avatar .author-info{  margin-left: 15px; }
.testimonial p{ font-size: 14px; line-height: 22px; }
.feature-step-2-title-2-right {width: 100%;}
.feature-step-2-box {padding:20px 10px;}
.feature-step-2-box p{font-size: 13px;}
.service-blog b {font-size: 220px;}

/*blog*/
.blog .timeline > li > .timeline-badge { font-size: 15px; height: 44px; line-height: 12px; width: 44px; }
.blog ul.timeline:before { left: 27px; }
.blog ul.timeline > li > .timeline-badge { left: 5px; }
.blog ul.timeline > li > .timeline-panel { width: 100%; }
.blog .timeline li.entry-date span { display: none;}
.blog ul.timeline::before { display: none; }
.blog ul.timeline > li > .timeline-badge { display: none; }
.blog ul.timeline > li > .timeline-panel::before { display: none; }
.blog ul.timeline > li > .timeline-panel::after { display: none; }
.blog .timeline li.timeline-inverted .timeline-panel::after { display: none; }
.blog .timeline li.timeline-inverted .timeline-panel::before { display: none; }
.blog.timeline-sidebar .timeline li { width: 100%; }
.blog.blog-single .port-navigation .port-photo { width: 104px; }

/*shop*/
.offer-banner-1 h1 { font-size: 20px; }
.deal-banner .counter-deal ul li { padding: 7px 10px; margin-right: 2px; }
.deal-banner .caption h2 { font-size: 32px; }
.deal-banner.maintenance-progress-bar { margin: 0; }
.bottom-nav .owl-nav .owl-next { right: 36%; }
.bottom-nav .owl-nav .owl-prev { left: 36%; }
.action-box.pattern { padding: 70px 15px; }
.action-box { padding: 30px 15px; }
.appointment-bg { padding: 60px 15px; }
.appointment a.pull-left { display: block; }

/*mobile app*/
.mobile-app-about a img { margin-bottom: 10px; }
.mobile-app-about h2 { font-size: 30px; line-height: 30px; }
.phone-holder { width: 260px; }
.highlights-phone.wht .phone-holder #fon { background:transparent;  }
.highlights-phone.wht .hgi { top: 0; }
.highlights-phone { height: 550px; }

/*one page*/
.typer-banner h1 { font-size: 30px; line-height: 30px; }
.popup-video-banner h1 { font-size: 30px; line-height: 40px; }
.video-background-banner h1 { font-size: 30px; line-height: 30px; }
.video-background-banner .slider-content { padding: 30px 15px; }
.personal-typer-banner h1 { font-size: 30px; line-height: 30px; margin-bottom: 0px; }
.personal-typer-banner h1 span { font-size: 20px; left: -10px; top: 0; }
.image-content { width: 100%; margin-right: 0; }

/*banner*/
#main-slider .heading-extra-large{ font-size: 24px; line-height: 28px; }
#main-slider .heading-large { font-size: 22px; line-height: 24px; }
#main-slider .heading-small { font-size: 10px; line-height: 12px; }
#main-slider .heading-med { font-size: 14px; line-height: 20px;}
#main-slider .heading-dots li{  padding-left: 15px; margin-left: 10px;}
#main-slider .heading-dots li:after{ width: 10px; height: 10px;  top: 5px; }
#main-slider .carousel-indicators{ bottom: 0; display: none;}
#main-slider .heading-underline:after{ height: 6px; bottom: 5px;  }
.button{padding: 6px 6px;font-size: 11px;}
.button span{font-size: 10px;}
#main-slider .carousel-control.left{ left: -20px; right: inherit; }
#main-slider .right.carousel-control {right: -20px;left: inherit;}
#main-slider .carousel-indicators li {width: 10px !important; height: 10px !important;}
#main-slider .carousel-control i {line-height: 36px;font-size: 20px;padding: 15px 10px;}
#main-slider .carousel-indicators li { width: 12px !important; height: 12px !important; border: 2px solid #fff !important; margin: 1px !important;}
.banner-patarn{margin-top: -10.5%; display: none;}
#main-slider.gardener .carousel-control.right{ top: 48%; }
#main-slider.gardener .slider-content h6{ font-size: 14px;  }

/*h-sm-400*/
.h-xs-300 {height: 300px !important;}

/*new-bs-4*/
.btn { padding: 10px 7px;  font-size: 12px;  line-height: 12px !important; height: 34px; }
.btn-outline-light { height:34px; }
.btn.btn-sm{padding: .7rem .7rem;}
#main-slider .carousel-control.left, #main-slider .carousel-control.right{ top: 48%; }
.play-video { width: 60px; height: 60px; }
.play-video a i { line-height: 60px; font-size: 20px; }

/*index-02 Renovation*/
#main-slider.renovation .carousel-control.left, #main-slider.renovation .carousel-control.right { top: 65%; }
#main-slider.renovation-banner .heading-extra-large { font-size: 20px; line-height: 24px;}

/*index-03 Mechanic*/
#main-slider.mechanic .carousel-control.left, #main-slider.mechanic .carousel-control.right{ top: 66%; }
#main-slider.mechanic .heading-extra-large {  font-size: 28px;    line-height: 34px; }

/*index-04 Gardener*/
#main-slider.gardener .slider-content h6{ display: none; }

/*index-05 Moving*/
#main-slider.moving .border-l:before {left: 0; top: 4px; width: 5px; height: 40px;}
#main-slider.moving .border-r:before {right: 0; top: 4px; width: 5px; height: 40px;}

/*index-06 Cleaning*/
#main-slider.cleaning .carousel-control.left, #main-slider.cleaning .carousel-control.right { top: 50%; }
#main-slider.cleaning .rotate{ display: none; }
#main-slider.cleaning .heading-small{ margin-bottom: 5px;  }

/*index-07 Car-wash*/
#main-slider.carwash .heading-large {font-size: 25px; line-height: 16px;}
#main-slider.carwash .heading-med { font-size: 8px;line-height: 9px;}

/*index-08 Electrician*/
#main-slider.electrician .slider-content p{ font-size: 11px;line-height: 13px;}

/*index-09 Carpenter*/
#main-slider.carpenter .carousel-control.left, #main-slider.carpenter .carousel-control.right{ top: 48%; }
#main-slider.carpenter .heading-extra-large, #main-slider.moving .heading-large{ font-size: 20px; line-height: 24px;   }
}

@media(max-width:392px) {
#main-slider .heading-small{ font-size: 10px; line-height: 14px;  }
#main-slider .heading-med{ font-size: 12px; line-height: 14px;  }
#main-slider .heading-large{ font-size: 18px; line-height: 20px;  }
#main-slider.mechanic .heading-extra-large {font-size: 26px; line-height: 32px;}
#main-slider.moving .slider-content h5{ font-size: 12px; line-height: 16px;  }
.topbar .topbar-left ul li{margin-right: 0px !important; margin-bottom: 10px;}
.topbar .topbar-left ul li:last-child{margin-bottom: 0px;}

/*index-03 Mechanic*/
#main-slider.mechanic .heading-small {font-size: 14px;line-height: 14px;}

/*index-06 Cleaning*/
#main-slider.cleaning .call-icon i{ font-size: 24px;  }

/*index-07 Car-wash*/
#main-slider.carwash .heading-large {font-size: 18px; line-height: 5px;}
#main-slider.carwash .heading-med { font-size: 8px;line-height: 5px;}

/*index-09 Carpenter*/
#main-slider.carpenter .heading-med { font-size: 12px; line-height: 16px; }
#main-slider.carpenter .heading-large { font-size: 18px; line-height: 24px; margin-bottom: 10px; }
#main-slider.carpenter .btn.btn-lg{ padding: 5px 8px; letter-spacing: 2px; }
#main-slider.carpenter .border-lr:before{ width: 40px; left: -50px; }
#main-slider.carpenter .border-lr:after { width: 40px; right: -50px; }

/*maintenance*/
.search-box {	width: 250px;}
.countdown.medium { margin: 5px; }

/*.page-title*/
.page-title {	padding-bottom: 40px;}
.topbar .topbar-call.text-left li {	margin-bottom: 5px;}

/*.page-title*/
.testimonial-avatar .author-info{ left:25%; }
.nav.nav-tabs a.nav-link { padding: 8px;  }
.nav.nav-tabs li a{ padding: 8px 8px;  }
.nav-pills .nav-link { padding: 8px; }
}




