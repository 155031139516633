/*

Template:  Work House - Responsive Multi-purpose HTML5 Template
Author: https://www.potenzaglobalsolutions.com
Design and Developed by: https://www.potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template. Please do not change anything here! write in a custom.css file if required!

*/

/*================================================
[  Table of contents  ]
================================================
:: Header
:: Banner
:: Images Background
:: Features
:: About Us
:: Portfolio
:: Creativity
:: Sidebar
:: Blog
:: Error Block
:: Coming Soon
:: Register
:: Maintenance
:: Our History
:: Process
:: Careers
:: Sitemap
:: Privacy-Policy
:: Terms and Conditions
:: Special-Eature
:: BG - Images
:: Footer
:: google-map
:: youtube-video

======================================
[ End table content ]
======================================*/

/*************************
      Header
*************************/

/*topbar*/
.topbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 13px;
}
.topbar ul li,
.topbar ul li a {
  display: inline-block;
  color: #ffffff;
}
.topbar ul li a:hover {
  color: #3952d0;
}
.topbar .social-icons ul li {
  margin: 0;
}
.topbar .social-icons li a {
  width: 30px;
  height: 30px;
  font-size: 13px;
}
.topbar .topbar-left {
  padding: 15px 0;
}
.topbar .topbar-right {
  padding: 15px 0;
}
.topbar .topbar-right ul li {
  padding: 0 8px;
}
.topbar .topbar-right ul li:last-child {
  padding-right: 0;
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-inverse {
  background: transparent;
  border: 0;
}
.navbar-brand {
  height: auto;
  padding: 25px 0;
}
.navbar .navbar-brand img {
  height: 40px;
}
.navbar {
  min-height: 80px;
}
.navbar-nav {
  float: right;
}
.navbar-inverse .navbar-nav > li {
  padding: 35px 15px;
}
.navbar-inverse .navbar-nav > li > a {
  color: #ffffff;
  padding: 0;
}
.navbar-inverse .navbar-nav > li > a:hover {
  color: #3952d0;
}
.navbar-container {
  position: relative;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.05);
}
.megamenu > .dropdown-menu {
  left: auto;
  right: 0;
  width: 100%;
}
.navbar-nav .dropdown-menu::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: transparent;
  margin-top: 0;
}
.navbar-nav .dropdown-menu .dropdown-menu {
  left: auto;
  margin: 0;
  right: 100%;
  top: 0;
}
.navbar-nav .dropdown-menu .dropdown-item {
  padding: 8px 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
}
.navbar-nav .dropdown-submenu:before {
  content: "\e6e2";
  position: absolute;
  top: 10px;
  right: 25px;
  font-family: "themify";
  font-size: 12px;
}
.dropdown-menu {
  border: none;
  margin: 0;
  border-radius: 0;
  padding: 10px;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: transparent;
}
.dropdown-item.active,
.dropdown-item:active {
  background: transparent;
}
.navbar .navbar-nav .nav-link:hover {
  color: #3952d0;
}
.navbar .navbar-nav .active > .nav-link,
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .show > .nav-link {
  color: #3952d0;
  font-weight: 500;
}
.navbar .navbar-nav .nav-link {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #626262;
  margin: 26px 0;
  padding-left: 15px;
  padding-right: 15px;
}
.navbar .nav-title {
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.navbar .megamenu .dropdown-menu {
  padding: 40px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background: transparent;
}
.navbar-nav .dropdown-menu .dropdown-item {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 8px 0 8px 0;
  font-family: "Poppins", sans-serif;
  color: #323232;
}
.navbar-nav .dropdown-menu .dropdown-item i {
  padding-right: 10px;
}
.navbar .navbar-nav .dropdown-menu .dropdown-item:after {
  top: inherit;
  content: "";
  display: inline-block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 4px;
  left: 0;
  background: #3952d0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.navbar .navbar-nav .dropdown-menu .dropdown-item:hover:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
.navbar .dropdown-menu {
  min-width: 12rem;
}
.navbar .dropdown-menu {
  padding: 20px 0px;
}
.navbar .dropdown-menu > li {
  padding: 0px 30px;
}
.navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0;
}
.menu-bg {
  background: #ffffff url(../images/menu-bg.jpg);
  background-position: bottom right;
  background-repeat: no-repeat;
}

/*active*/
.navbar .navbar-nav .dropdown-menu li.active > .dropdown-item:after {
  background: #3952d0;
  webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

/*header default*/
.header.default {
  position: absolute;
  width: 100%;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}
.header.default .navbar .navbar-nav .nav-link {
  color: #ffffff;
}
.header.default .navbar .navbar-nav .nav-link:hover {
  color: #3952d0;
}
.header.default .navbar .navbar-nav .active > .nav-link,
.header.default .navbar .navbar-nav .nav-link.active,
.header.default .navbar .navbar-nav .nav-link.show,
.header.default .navbar .navbar-nav .show > .nav-link {
  color: #3952d0;
}

/*header dark*/
.header.dark-topbar {
  background: #120b00 !important;
}
.header.dark-topbar .navbar .navbar-nav .nav-link {
  color: #ffffff;
}
.header.dark-topbar .navbar .navbar-nav .nav-link:hover {
  color: #3952d0;
}
.header.dark-topbar .navbar .navbar-nav .active > .nav-link,
.header.dark-topbar .navbar .navbar-nav .nav-link.active,
.header.dark-topbar .navbar .navbar-nav .nav-link.show,
.header.dark-topbar .navbar .navbar-nav .show > .nav-link {
  color: #3952d0;
}

/*header fancy*/
.header.fancy .navbar {
  position: absolute;
  width: 100%;
  z-index: 999;
  left: 0;
  top: 30px;
  padding: 0 20px;
}

/*header fancy*/
.header.fancy-topbar .topbar {
  background: #3952d0;
  border: 0;
  padding: 0px 0px 60px;
}
.header.fancy-topbar .navbar {
  position: absolute;
  width: 100%;
  z-index: 999;
  top: -60px;
  padding: 0 20px;
  left: 0;
}
.header.fancy-topbar .topbar a:hover {
  color: #323232;
}

/*header dark*/
.header.light-topbar .topbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.header.light-topbar .topbar ul li,
.header.light-topbar .topbar ul li a {
  color: #626262;
}
.header.light-topbar .topbar ul li a:hover {
  color: #3952d0;
}

/*transparent*/
.header.default.transparent {
  background: rgba(0, 0, 0, 0.5);
}

/*Navbar-blog*/
.navbar-nav .dropdown-menu .blog-card:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #3952d0;
  opacity: 0.9;
}
.navbar-nav .dropdown-menu .blog-card .card-body {
  position: absolute;
  bottom: 0;
  padding: 20px 15px 30px;
}
.navbar-nav .dropdown-menu .blog-card .card-body .blog-tags a {
  font-size: 13px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}
.navbar-nav .dropdown-menu .blog-card .card-body .blog-title a {
  font-size: 17px;
  line-height: 28px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 992px) {
  .megamenu {
    position: static;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .show > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    z-index: 1000;
  }
  .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    z-index: 111;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  .dropdown > .dropdown-toggle:active {
    pointer-events: none;
  }
}

/*Sticky Header*/
.header.is-sticky {
  position: fixed !important;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  height: auto;
  z-index: 999;
}
.header.is-sticky .topbar {
  display: none;
}
.header.is-sticky .header-contact-info {
  display: none !important;
}
.header.is-sticky .navbar {
  position: relative;
  top: 0px;
  right: 0px;
  left: 0;
  border-top: none !important;
  width: 100%;
}
.header.default.is-sticky {
  background-color: #120b00;
}
.header.fancy-topbar.is-sticky .navbar {
  padding-left: 0;
  padding-right: 0;
}
.header.fancy-topbar.is-sticky .navbar-container .row [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

/*************************************
       animations banner
**************************************/
#main-slider {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  position: relative;
}
#main-slider .item img {
  width: 100%;
}
#main-slider .item .slider-content {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  -ms-transition: opacity 500ms;
  transition: opacity 500ms;
}
#main-slider .item.active .slider-content {
  z-index: 0;
  opacity: 1;
  -webkit-transition: opacity 100ms;
  -ms-transition: opacity 100ms;
  -moz-transition: opacity 100ms;
  -o-transition: opacity 100ms;
  transition: opacity 100ms;
}
#main-slider .slider-content {
  display: inline-block;
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 100%;
  z-index: 2;
}

/*indicators*/
#main-slider .swiper-pagination {
  bottom: 30px;
}
#main-slider .swiper-pagination .swiper-pagination-bullet {
  width: 14px !important;
  height: 14px !important;
  border: 2px solid #fff !important;
  margin: 1px !important;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  opacity: 1;
}
#main-slider
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 1);
}
#main-slider .carousel-indicators {
  bottom: 30px;
}
#main-slider .carousel-indicators li {
  width: 14px !important;
  height: 14px !important;
  border: 2px solid #fff !important;
  margin: 1px !important;
}
#main-slider .heading-small {
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
#main-slider .heading-med {
  font-size: 24px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
#main-slider .heading-large {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 2px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
#main-slider .heading-extra-large {
  font-size: 68px;
  line-height: 74px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
#main-slider .heading-underline {
  position: relative;
}
#main-slider .heading-underline:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 20px;
  width: 100%;
  height: 8px;
  background: #3952d0;
}
#main-slider .heading-fill {
  display: inline-block;
  padding: 5px 10px;
}
#main-slider .heading-dots li {
  position: relative;
  padding-left: 30px;
  margin-left: 30px;
}
#main-slider .heading-dots li:first-child {
  margin-left: inherit;
}
#main-slider .heading-dots li:after {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
}

/*swiper-button*/
#main-slider .swiper-button-prev,
#main-slider .swiper-button-next {
  background-image: inherit;
}
#main-slider .swiper-button-prev i,
#main-slider .swiper-button-next i {
  font-size: 32px;
  color: #ffffff;
}

/*-- Animation --*/
.carousel-item.active .animated1 {
  -webkit-animation: lightSpeedIn 1s ease-in 200ms both;
  -o-animation: lightSpeedIn 1s ease-in 200ms both;
  -ms-animation: lightSpeedIn 1s ease-in 200ms both;
  -moz-animation: lightSpeedIn 1s ease-in 200ms both;
  animation: lightSpeedIn 1s ease-in 200ms both;
}
.carousel-item.active .animated2 {
  -webkit-animation: bounceInRight 1s ease-in-out 500ms both;
  -o-animation: bounceInRight 1s ease-in-out 500ms both;
  -ms-animation: bounceInRight 1s ease-in-out 500ms both;
  -moz-animation: bounceInRight 1s ease-in-out 500ms both;
  animation: bounceInRight 1s ease-in-out 500ms both;
}
.carousel-item.active .animated3 {
  -webkit-animation: bounceInLeft 1s ease-in-out 500ms both;
  -o-animation: bounceInLeft 1s ease-in-out 500ms both;
  -ms-animation: bounceInLeft 1s ease-in-out 500ms both;
  -moz-animation: bounceInLeft 1s ease-in-out 500ms both;
  animation: bounceInLeft 1s ease-in-out 500ms both;
}
.carousel-item.active .animated4 {
  -webkit-animation: flipInX 1s ease-in 500ms both;
  -o-animation: flipInX 1s ease-in 500ms both;
  -ms-animation: flipInX 1s ease-in 500ms both;
  -moz-animation: flipInX 1s ease-in 500ms both;
  animation: flipInX 1s ease-in 500ms both;
}
.carousel-item.active .animated5 {
  -webkit-animation: bounceInLeft 1s ease-in-out 100ms both;
  -o-animation: bounceInLeft 1s ease-in-out 100ms both;
  -ms-animation: bounceInLeft 1s ease-in-out 100ms both;
  -moz-animation: bounceInLeft 1s ease-in-out 100ms both;
  animation: bounceInLeft 1s ease-in-out 100ms both;
}
.carousel-item.active .animated6 {
  -webkit-animation: bounceIn 1s ease-in 500ms both;
  -o-animation: bounceIn 1s ease-in 500ms both;
  -ms-animation: bounceIn 1s ease-in 500ms both;
  -moz-animation: bounceIn 1s ease-in 500ms both;
  animation: bounceIn 1s ease-in 500ms both;
}
.carousel-item.active .animated7 {
  -webkit-animation: fadeInDown 0.7s ease-in 1000ms both;
  -o-animation: fadeInDown 0.7s ease-in 1000ms both;
  -moz-animation: fadeInDown 0.7s ease-in 1000ms both;
  -ms-animation: fadeInDown 0.7s ease-in 1000ms both;
  animation: fadeInDown 0.7s ease-in 1000ms both;
}
.carousel-item.active .animated8 {
  -webkit-animation: fadeInUp 0.7s ease-in 1000ms both;
  -o-animation: fadeInUp 0.7s ease-in 1000ms both;
  -moz-animation: fadeInUp 0.7s ease-in 1000ms both;
  -ms-animation: fadeInUp 0.7s ease-in 1000ms both;
  animation: fadeInUp 0.7s ease-in 1000ms both;
}
.carousel-item.active .animated9 {
  -webkit-animation: bounceInRight 1s ease-in-out 100ms both;
  -o-animation: bounceInRight 1s ease-in-out 100ms both;
  -ms-animation: bounceInRight 1s ease-in-out 100ms both;
  -moz-animation: bounceInRight 1s ease-in-out 100ms both;
  animation: bounceInRight 1s ease-in-out 100ms both;
}
.banner-patarn {
  position: relative;
  height: 128px;
  margin-top: -5%;
  z-index: 9;
  background-size: contain;
  background-repeat: no-repeat;
}
.clothes-section {
  position: relative;
}
.clothes-section.bottom-layer:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 268px;
  background-color: #ffffff;
}

/*************************************
          Banner
**************************************/

/*index-01 Plumber*/
.h-800 {
  height: 800px !important;
}
.h-900 {
  height: 900px !important;
}
.swiper-button-next,
.swiper-button-prev {
  height: 32px !important;
  text-align: center;
}
#main-slider.plumber .swiper-button-next {
  right: 30px;
}
#main-slider.plumber .swiper-button-prev {
  left: 20px;
}
#main-slider.plumber .swiper-button-next i,
#main-slider.plumber .swiper-button-prev i {
  font-size: 22px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
#main-slider.plumber .swiper-button-next:hover i,
#main-slider.plumber .swiper-button-prev:hover i {
  background: rgba(0, 0, 0, 1);
}

/*index-02 Renovation*/
.widget-clients img,
.clients-list img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.widget-clients img:hover,
.clients-list img:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

/*index-03 Mechanic*/
#main-slider.mechanic .border-b {
  width: 8%;
  margin: 0 1%;
}
#main-slider.mechanic .carousel-control.right {
  right: 20px;
  left: inherit;
}
#main-slider.mechanic .swiper-button-prev {
  top: 46%;
}
#main-slider.mechanic .swiper-button-next {
  top: 54%;
}

/*index-05 Moving*/
#main-slider.moving h1 .text-stroke-white {
  color: transparent !important;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #ffffff;
  display: block;
}
#main-slider.moving h1 {
  font-size: 64px;
  line-height: 74px;
}

/*index-06 Cleaning*/
#main-slider.cleaning .slider-left {
  display: inline-block;
  padding-right: 15px;
  vertical-align: top;
}
#main-slider.cleaning .slider-right {
  display: inline-block;
}
#main-slider.cleaning .rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  writing-mode: tb-rl;
  margin: 10px 16px 0 0px;
}
#main-slider.cleaning h3.heading-small {
  line-height: 16px;
}
#main-slider.cleaning .border-rotate {
  position: relative;
  bottom: 60px;
}
#main-slider.cleaning .border-rotate:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0px;
  width: 2px;
  height: 70px;
  background: #2a9be8;
}
#main-slider.cleaning .call-icon i {
  font-size: 40px;
  margin-right: 10px;
}

/*index-07 Carwash*/
.home-07 .card-body span {
  font-size: 70px;
}

/*index-08 Electrician*/
.home-08 h1 {
  font-size: 90px;
}

/*index-09 Carpenter*/
.contact-form-overlay {
  position: relative;
  z-index: 1;
}
.contact-form-overlay:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background: #ba893f;
  opacity: 0.7;
  z-index: -1;
  border-radius: 0.25rem;
}

/*index-10 Welder*/
#main-slider.welder ul {
  padding: 0;
  margin-bottom: 20px;
}
#main-slider.welder ul li {
  display: inline-block;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin-right: 30px;
}
#main-slider.welder ul li:last-child {
  margin-right: 0px;
}
#main-slider.welder ul li i {
  padding-right: 20px;
}
#main-slider.welder .swiper-slide h1 {
  font-size: 81px;
  line-height: 89px;
  margin-bottom: 20px;
}
#main-slider.welder .swiper-slide h3 {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 20px;
}
#main-slider.welder .swiper-button-next {
  right: 23px;
}
#main-slider.welder .swiper-button-next i,
#main-slider.welder .swiper-button-prev i {
  font-size: 22px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
#main-slider.welder .swiper-button-next:hover i,
#main-slider.welder .swiper-button-prev:hover i {
  background: rgba(0, 0, 0, 1);
}

/*************************************
      slider-parallax one page
**************************************/
.slider-parallax {
  height: 86vh;
  position: relative;
}
.slider-content-middle {
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/*scroll-down*/
.scroll-down {
  text-align: center;
  width: 34px;
  height: 58px;
  display: inline-block;
  border-radius: 20px;
  border: 2px solid #fff;
  position: absolute;
  left: 50%;
  bottom: 60px;
  margin-left: -17px;
  line-height: 50px;
}
.scroll-down i {
  height: 10px;
  width: 4px;
  background: #fff;
  display: inline-block;
  border-radius: 3px;
  -webkit-animation-name: fadebounce;
  -moz-animation-name: fadebounce;
  -ms-animation-name: fadebounce;
  -o-animation-name: fadebounce;
  animation-name: fadebounce;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -ms-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-moz-keyframes fadebounce {
  0% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -moz-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fadebounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes fadebounce {
  0% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -o-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-ms-keyframes fadebounce {
  0% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -ms-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadebounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/*popup-video-banner*/
.popup-video-banner h1 {
  font-size: 60px;
  line-height: 80px;
  font-weight: 400;
}
.popup-video-banner a span {
  margin-left: 6px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  background: #ffffff;
  color: #3952d0;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}
.popup-video-banner a:hover span {
  background: #3952d0;
  color: #ffffff;
}
.popup-video-banner a strong {
  padding-left: 10px;
  display: inline-block;
}

/*************************************
    			Background
 **************************************/
/*images background*/

/*Theme*/
.bg-overlay-theme-30:before {
  background: #3952d0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.3;
}
.bg-overlay-theme-40:before {
  background: #3952d0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.4;
}
.bg-overlay-theme-50:before {
  background: #3952d0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.5;
}
.bg-overlay-theme-60:before {
  background: #3952d0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.6;
}
.bg-overlay-theme-70:before {
  background: #3952d0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.7;
}
.bg-overlay-theme-80:before {
  background: #3952d0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.8;
}
.bg-overlay-theme-90:before {
  background: #3952d0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.9;
}

/*Black*/
.bg-overlay-black-10:before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-black-20:before {
  background: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-black-30:before {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-black-40:before {
  background: rgba(0, 0, 0, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-black-50:before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.bg-overlay-black-60:before {
  background: rgba(0, 0, 0, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-black-70:before {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-black-80:before {
  background: rgba(0, 0, 0, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-black-90:before {
  background: rgba(0, 0, 0, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*White*/
.bg-overlay-white-10:before {
  background: rgba(255, 255, 255, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-20:before {
  background: rgba(255, 255, 255, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-30:before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-40:before {
  background: rgba(255, 255, 255, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-50:before {
  background: rgba(255, 255, 255, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-60:before {
  background: rgba(255, 255, 255, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-70:before {
  background: rgba(255, 255, 255, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-80:before {
  background: rgba(255, 255, 255, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-white-90:before {
  background: rgba(255, 255, 255, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*Green*/
.bg-overlay-green-20:before {
  background: rgba(132, 186, 63, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-green-50:before {
  background: rgba(132, 186, 63, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-green-70:before {
  background: rgba(132, 186, 63, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-green-90:before {
  background: rgba(132, 186, 63, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*Orange*/
.bg-overlay-orange-30:before {
  background: rgba(150, 38, 18, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-orange-40:before {
  background: rgba(150, 38, 18, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-orange-50:before {
  background: rgba(150, 38, 18, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-orange-60:before {
  background: rgba(150, 38, 18, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-orange-70:before {
  background: rgba(150, 38, 18, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*Blue*/
.bg-overlay-blue-30:before {
  background: rgba(5, 27, 46, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-blue-40:before {
  background: rgba(5, 27, 46, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-blue-50:before {
  background: rgba(5, 27, 46, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-blue-60:before {
  background: rgba(5, 27, 46, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-blue-70:before {
  background: rgba(5, 27, 46, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-blue-80:before {
  background: rgba(5, 27, 46, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-blue-90:before {
  background: rgba(5, 27, 46, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*gold*/
.bg-overlay-gold-30:before {
  background: rgba(186, 137, 63, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-gold-40:before {
  background: rgba(186, 137, 63, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-gold-50:before {
  background: rgba(186, 137, 63, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-gold-60:before {
  background: rgba(186, 137, 63, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-gold-70:before {
  background: rgba(186, 137, 63, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-gold-80:before {
  background: rgba(186, 137, 63, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.bg-overlay-gold-90:before {
  background: rgba(186, 137, 63, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/*index-10*/
.special-feature .card .card-body {
  padding: 50px;
}

/*************************************
   Features all  ( all pages Features )
 **************************************/
.awesome-features .img-side img {
  position: absolute;
  top: -30px;
  left: 0;
}
.feature-box {
  border: 1px solid #e6e6e6;
  padding: 1px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
}
.feature-box-content {
  position: relative;
  padding: 30px;
  z-index: 9;
}
.feature-box i {
  width: 100%;
  color: #3952d0;
  padding: 0 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.feature-box h4 {
  text-transform: none;
  padding: 30px 0 10px;
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
}
.feature-box p {
  transition: all 0.3s ease-out 0s;
}
.feature-box a {
  font-size: 16px;
  font-weight: 300;
  color: #808080;
  margin: 25px 0 10px;
  padding: 0 30px;
  display: block;
  position: relative;
  z-index: 3;
}
.feature-box .feature-box-img {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 0;
  background-size: cover;
  background-position: center center;
}
.feature-box .border {
  position: absolute;
  background: #3952d0;
  height: 0px;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.feature-box:hover .feature-box-img,
.feature-box.active .feature-box-img {
  opacity: 1;
}
.feature-box:hover .border,
.feature-box.active .border {
  height: 45px;
}
.feature-box:hover i,
.feature-box.active i {
  background: rgba(255, 255, 255, 0);
  color: #fff;
}
.feature-box:hover h4,
.feature-box.active h4,
.feature-box:hover p,
.feature-box.active p {
  color: #fff;
}
.feature-box:hover span,
.feature-box.active span {
  color: #fff;
}
.feature-box:hover a,
.feature-box.active a {
  color: #fff;
}
.feature-box:hover:before,
.feature-box.active:before {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  color: #fff;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/*split-section*/
.split-section {
  position: relative;
  z-index: 10;
}
.split-section .img-holder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.split-section .img-side.img-right {
  right: 0;
}
.split-section .img-side.img-left {
  left: 0;
}
.split-section .img-side {
  right: 0;
  padding: 0;
  position: absolute !important;
  top: 0px;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}

/*************************************
         About Us
 **************************************/
.who-we-are-left .owl-carousel .owl-dots {
  bottom: 26px;
}
.who-we-are-left .owl-carousel.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

/*************************************
         Portfolio
 **************************************/
.isotope-filters {
  display: table;
  margin: 0 auto 50px;
  text-align: center;
}
.isotope-filters.text-left {
  display: block;
  margin: 30px 0;
  text-align: left;
}
.isotope-filters button {
  margin: 4px;
  cursor: pointer;
  padding: 6.5px 25px;
  font-size: 16px;
  border-radius: 4px;
  background: transparent;
  color: #363636;
  font-weight: 600;
  border: 1px solid #ccc;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.isotope-filters button:focus {
  outline: none;
  outline-style: none;
  outline-offset: 0;
}
.isotope-filters button.active,
.isotope-filters button:hover {
  background: #3952d0;
  color: #fff;
  border-color: #3952d0;
}
.isotope-filters button + button {
  margin-left: 10px;
}
.isotope .grid-item img {
  width: 100%;
}
.isotope.columns-1 .grid-item {
  width: 100%;
  padding: 10px;
}
.isotope.columns-2 .grid-item {
  width: 50%;
  padding: 10px;
}
.isotope.columns-3 .grid-item {
  width: 33.33%;
  padding: 10px;
}
.isotope.columns-4 .grid-item {
  width: 25%;
  padding: 10px;
}
.isotope.columns-5 .grid-item {
  width: 20%;
  padding: 10px;
}
.isotope.no-padding .grid-item {
  padding: 0 !important;
}
.masonry.no-padding .masonry-item {
  padding: 0 !important;
}
.masonry .masonry-item img {
  width: 100%;
}
.masonry.columns-2 .masonry-item {
  width: 50%;
  padding: 10px;
}
.masonry.columns-3 .masonry-item {
  width: 33.33%;
  padding: 10px;
}
.masonry.columns-4 .masonry-item {
  width: 25%;
  padding: 10px;
}
.masonry.columns-5 .masonry-item {
  width: 20%;
  padding: 10px;
}
.isotope.columns-4 .portfolio-item .portfolio-overlay h4 {
  font-size: 16px;
}
.isotope.columns-4 .portfolio-item .portfolio-overlay h6 {
  display: none;
}

/*************************************
         Portfolio  Item
 **************************************/
.portfolio-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  color: #fff;
  width: 100%;
  border: 0;
  position: relative;
}
.portfolio-item img {
  width: 100%;
  -webkit-transition: -ms-transform 0.35s -o-transform 0.35s -moz-transform
    0.35s;
  transition: transform 0.35s;
  -webkit-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.portfolio-item .portfolio-overlay {
  width: 100%;
  background: #3952d0;
  padding: 10px 0px;
  position: absolute;
  bottom: -80px;
  display: block;
  margin-top: 0;
  float: left;
  z-index: 9;
  text-align: left;
  padding-left: 20px;
  left: 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.portfolio-item .portfolio-overlay h4 {
  font-weight: 600;
  margin-bottom: 5px;
}
.portfolio-item .portfolio-overlay a:hover {
  color: #323232;
}
.portfolio-item .portfolio-overlay span {
  font-weight: 400;
  font-size: 14px;
}
.portfolio-item .portfolio-overlay span a {
  color: #ffffff;
}
.portfolio-item .portfolio-overlay span a:hover {
  color: #323232;
}
.portfolio-item a.popup {
  position: absolute;
  right: 20px;
  top: -20px;
  color: #fff;
  z-index: 9;
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #fff;
  color: #626262;
  border-radius: 50%;
  margin-right: 10px;
  padding-left: 0px;
  text-align: center;
  line-height: 40px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.portfolio-item a.popup:hover {
  background: #3952d0;
  color: #ffffff;
}
.portfolio-item:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.portfolio-item:hover a.popup {
  opacity: 1;
  top: 20px;
}
.portfolio-item:before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: 115%;
  -webkit-transition: all 0.7s cubic-bezier(0.68, 0.11, 0.13, 0.98);
  -moz-transition: all 0.7s cubic-bezier(0.68, 0.11, 0.13, 0.98);
  transition: all 0.7s cubic-bezier(0.68, 0.11, 0.13, 0.98);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-carousel .owl-item .portfolio-item img {
  width: 100%;
}
.portfolio-item.only-popup {
  width: 100%;
  text-align: center;
}
.portfolio-item.only-popup a.popup {
  display: inline-block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.isotope.columns-5 .portfolio-item .portfolio-overlay h4 {
  font-size: 16px;
}
.portfolio-item:hover .portfolio-overlay {
  bottom: 0;
}

/*portfolio pupup*/
.mfp-bottom-bar {
  display: none;
}

/*************************************
	    	   Portfolio single page
**************************************/

/*tags*/
.tags h5 {
  display: inline-block;
  padding-right: 20px;
}
.tags ul {
  display: inline-block;
}
.tags li {
  display: inline-block;
}
.tags li a {
  background: #f6f7f8;
  color: #323232;
  border-radius: 20px;
  padding: 8px 16px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 2px;
}
.tags li a:hover {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

/*share*/
.share .social ul {
  display: inline-block;
}
.share .social ul li {
  display: inline-block;
  padding: 0px 6px;
}
.share .social ul li a:hover {
  color: #353535;
}

/*port-meta*/
.port-meta ul {
  margin-bottom: 30px;
}
.port-meta li {
  margin-bottom: 20px;
  font-size: 16px;
}
.port-meta li b {
  color: #3952d0;
  width: 200px;
  font-weight: 400;
  width: 100px;
  display: inline-block;
}
.port-meta li span {
  font-weight: normal;
}
.port-info p {
  margin-bottom: 0px;
}

/*port-navigation*/
.port-navigation {
  padding: 20px 0;
  border-bottom: 1px dashed #d0d0d0;
  border-top: 1px dashed #d0d0d0;
}
.port-navigation a {
  color: #626262;
}
.port-arrow {
  height: 74px;
  width: 40px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
}
.port-arrow i {
  text-align: center;
  font-size: 25px;
  line-height: 75px;
}
.port-photo {
  width: 124px;
  height: 77px;
  display: inline-block;
}
.port-photo img {
  width: 100%;
}

/*port-post*/
.port-post {
  background: #ffffff;
  padding: 15px;
}
.port-post-photo {
  width: 170px;
  display: inline-block;
  float: left;
  margin-right: 20px;
}
.port-post-photo img {
  width: 100%;
}

/*port-post-info*/
.port-post-info {
  padding-left: 190px;
}
.port-post-info h3 {
  display: inline-block;
  font-size: 24px;
}
.port-post-info span {
  color: #626262;
}
.port-post-social strong {
  font-size: 14px;
}
.port-post-social a {
  color: #626262;
  margin-left: 8px;
  font-size: 14px;
}
.port-post-social a:hover {
  color: #3952d0;
}
.port-post-info p {
  margin-top: 10px;
}
.port-singal .owl-carousel .owl-item img {
  width: 100%;
}

/*video vimeo and youtube*/
.js-video {
  height: 0;
  padding-top: 25px;
  padding-bottom: 54%;
  position: relative;
  overflow: hidden;
}
.js-video.widescreen {
  padding-bottom: 34.34%;
}
.js-video embed,
.js-video iframe,
.js-video object,
.js-video video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: none;
}
.js-video.big {
  padding-top: 84px;
}

/*blog-entry-audio*/
.blog-entry .blog-entry-audio {
  padding: 136px 20px;
  background: url(../images/blog/04.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
}
.blog-card {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  border-radius: 4px;
  overflow: hidden;
}
.blog-card:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}
.blog-title a {
  font-size: 22px;
  margin-bottom: 20px;
  line-height: 28px;
  font-weight: 700;
}
a.link {
  color: #323232;
}
.card.blockquote a.link:hover {
  color: #323232 !important;
}
.card.blockquote .blog-tags a.link {
  font-size: 14px;
}
.blog-card.blockquote {
  background: #3952d0;
  border: 0px;
  padding: 36px 30px;
}
.blog-card.blockquote blockquote {
  background: #3952d0;
  border-left: 0px;
  color: #ffffff;
  padding: 0px;
  position: relative;
  margin-top: 0 !important;
  padding-top: 60px;
}
.blog-card.blockquote blockquote:before {
  content: "\201C";
  font-family: Georgia, serif;
  font-size: 100px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  left: 0px;
  top: -20px;
}
.blog-single .blog-card.blockquote blockquote:before {
  color: #323232;
  top: 20px;
}
.blog-card.blockquote .card-body .card-title a:hover {
  color: #323232;
}
.blog-card.blockquote .blog-tags ul li a {
  color: #ffffff;
  font-size: 14px;
}
.blog-card.blockquote .blog-tags ul li a:hover {
  color: #323232;
}
.blog-card.blockquote .button.arrow {
  color: #ffffff;
}
.blog-card.blockquote .button.arrow:hover {
  color: #323232;
}
.blog-card .grid-post li {
  float: left;
  width: 50%;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  list-style: none;
}
.blog-card .grid-post li img {
  width: 100%;
}
.blog-card .grid-post li .portfolio-item {
  width: 100%;
}
.blog-card .blog-audio {
  padding: 136px 20px;
  background: url(../images/blog/04.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
}
.blog-single .blog-card.blog-content {
  background-color: inherit;
}
.blog-single .blog-card.blog-content:hover {
  box-shadow: inherit;
}
.blog-card ul.inline-links {
  display: inline-block;
}
.blog-card .inline-links li {
  display: inline-block;
  padding: 0 5px;
}
.card-img-overlay {
  background: rgba(0, 0, 0, 0.6);
}
.card-title.post-category a {
  display: inline-block;
  padding: 6px 15px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  background-color: #3952d0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: ll 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

/*************************************
	    	Creativity
**************************************/
.contact-map iframe {
  width: 100%;
  height: 100%;
}
#formmessage {
  display: none;
}
.contact-form .section-field {
  position: relative;
  width: 31.3%;
  margin-right: 3%;
  float: left;
}
.contact-form .section-field:nth-child(3) {
  margin-right: 0px;
}
.contact-form .section-field:nth-child(6) {
  margin-right: 0px;
}
.contact-form .form-control {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 4px;
  height: auto;
}
.form-control.nice-select {
  line-height: 1.5;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(120, 130, 140, 0.13);
}
.form-control.nice-select:active,
.form-control.nice-select.open,
.form-control.nice-select:focus {
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}
.nice-select.form-control .list {
  width: 100%;
}
.section-field.textarea {
  width: 100%;
}
.contact-form textarea.form-control {
  width: 100%;
}
.contact-2 .addresss-info p,
.contact-2 .addresss-info i,
.contact-2 .addresss-info li {
  color: #ffffff;
}
.contact-2 .contact-add {
  padding: 80px 0;
}
.contact-2 .contact-add i {
  font-size: 40px;
}
.contact-3 #map {
  padding-top: 100%;
}
.contact-2 #map {
  padding-top: 80%;
}
.map-side {
  left: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 9;
}
.map-side.map-right {
  left: auto;
  right: 0;
}
#map {
  width: 100%;
  height: 100%;
}
.contact-3 .contact-add {
  padding: 80px 0;
}
.contact-3 .contact-add i {
  font-size: 40px;
  color: #3952d0;
}
.contact-3 .contact-form .section-field {
  width: 100%;
}
.contact-3 .bg-theme.contact-add i {
  color: #ffffff !important;
}
.our-clients.bg-theme {
  padding: 60px 0 170px;
}

/*************************************
         Sidebar
**************************************/
.sidebar-widget p {
  margin-bottom: 0px;
}

/*.widget-search */
.widget-search {
  position: relative;
}
.widget-search input {
  padding-right: 30px;
  color: #353535;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 4px;
}
.widget-search i {
  position: absolute;
  right: 0px;
  padding: 16px 22px;
  font-size: 14px;
  color: #626262;
  cursor: pointer;
}
.widget-search input:focus {
  border-color: #3952d0;
}

/*Recent Posts*/
.sidebar-widget .recent-post {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.sidebar-widget .recent-post .recent-post-image {
  float: left;
  margin-right: 15px;
}
.sidebar-widget .recent-post .recent-post-image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.sidebar-widget .recent-post .recent-post-info {
  overflow: hidden;
}
.sidebar-widget .recent-post .recent-post-info a {
  display: block;
  margin-bottom: 5px;
  color: #323232;
}
.sidebar-widget .recent-post .recent-post-info a:hover {
  color: #3952d0;
}
.sidebar-widget .recent-post .recent-post-info span {
  color: #3952d0;
  font-style: italic;
  font-size: 14px;
}
.sidebar-widget .recent-post .recent-post-info span i {
  padding-right: 10px;
}
.sidebar-widget .recent-post:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

/*widget-archives*/
.sidebar-widget .widget-categories li {
  display: block;
  margin-bottom: 6px;
}
.sidebar-widget .widget-categories li a {
  font-size: 14px;
  color: #626262;
  line-height: 32px;
  display: block;
}
.sidebar-widget .widget-categories li i {
  font-size: 16px;
  line-height: 17px;
  padding-left: 2px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
}
.sidebar-widget .widget-categories li a:hover {
  color: #3952d0;
}
.sidebar-widget .widget-categories li a:hover i {
  border-color: #3952d0;
  color: #3952d0;
}

/*widget-link*/
.sidebar-widget .widget-link li {
  display: block;
  margin-bottom: 6px;
}
.sidebar-widget .widget-link li a {
  font-size: 14px;
  color: #626262;
  line-height: 32px;
  display: block;
}
.sidebar-widget .widget-link li i {
  font-size: 16px;
  line-height: 17px;
  padding-left: 2px;
  margin-right: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
}
.sidebar-widget .widget-link li a:hover {
  color: #3952d0;
}

/*tags*/
.sidebar-widget .widget-tags li {
  display: inline-block;
}
.sidebar-widget .widget-tags li a {
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  color: #323232;
  border-radius: 4px;
  padding: 8px 16px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 2px;
}
.sidebar-widget .widget-tags li a:hover {
  background: #ffffff;
}

/*widget-testimonial*/
.sidebar-widget .testimonial-widget .testimonial-content {
  background: #ffffff;
  padding: 20px;
  font-style: italic;
  border-radius: 5px;
  position: relative;
}
.sidebar-widget .testimonial-widget .testimonial-content:before {
  position: absolute;
  content: "";
  bottom: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 7.5px 0 7.5px;
  border-color: #f6f7f8 transparent transparent transparent;
}
.sidebar-widget .testimonial-widget .testimonial-info .testimonial-avtar {
  display: table-cell;
  padding-right: 20px;
}
.sidebar-widget .testimonial-widget .testimonial-info .testimonial-avtar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.sidebar-widget .testimonial-widget .testimonial-info .testimonial-name {
  display: table-cell;
  vertical-align: top;
}
.sidebar-widget .testimonial-widget .testimonial-info .testimonial-name span {
  display: block;
  font-style: italic;
  font-size: 14px;
}
.sidebar-widget .testimonial-widget .testimonial-info:before {
  display: none;
}

/*widget-gallery*/
.sidebar-widget .widget-gallery ul li {
  float: left;
  width: 59px;
  margin: 2px;
  list-style: none;
}

/*widget-newsletter*/
.sidebar-widget .widget-newsletter {
  background: #f7f7f7;
  padding: 30px;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.sidebar-widget .widget-newsletter .newsletter-icon {
  position: absolute;
  right: 0;
  top: -16px;
}
.sidebar-widget .widget-newsletter .newsletter-icon i {
  font-size: 100px;
  z-index: 0;
  color: rgba(0, 0, 0, 0.05);
}
.sidebar-widget .widget-newsletter .form-control {
  background: #ffffff;
  box-shadow: none;
}

/*opening-time*/
.opening-time ul li {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid #262626;
  padding-bottom: 14px;
  margin-bottom: 14px;
}
.opening-time ul li:last-child {
  border-bottom: 0;
}

/*************************************
	    	   Blog Page
**************************************/
.owl-carousel img {
  width: 100%;
}

/*pagination-nav*/
.pagination {
  margin: 0px;
}
.pagination li a {
  font-size: 15px;
  font-weight: 500;
  margin: 0 5px;
  color: #626262;
  border-radius: 50%;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-radius: 50%;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 50%;
}
.pagination > .active > a,
.pagination > .active > a:hover {
  color: #626262;
}
.pagination > li > a,
.pagination > li > span {
  background: #f6f7f8;
  border: 0px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.pagination li a:focus,
.pagination li a:hover,
.pagination li span:focus,
.pagination li span:hover {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

/*timeline blog*/
.blog .timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}
.blog .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 4px;
  background-color: #f7f7f7;
  left: 50%;
  margin-left: -1.5px;
}
.blog .timeline > li {
  margin-bottom: 20px;
  position: relative;
  width: 46%;
  float: left;
  clear: left;
}
.blog .timeline > li:before,
.blog .timeline > li:after {
  content: " ";
  display: table;
}
.blog .timeline > li:after {
  clear: both;
}
.blog .timeline > li:before,
.blog .timeline > li:after {
  content: " ";
  display: table;
}
.blog .timeline > li:after {
  clear: both;
}
.blog .timeline > li > .timeline-panel {
  width: 95%;
  float: left;
  border: 1px solid #d4d4d4;
  position: relative;
  border: 1px solid #f7f7f7;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.blog .timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #f7f7f7;
  border-right: 0 solid #f7f7f7;
  border-bottom: 15px solid transparent;
  content: " ";
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.blog .timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.blog .timeline > li > .timeline-badge {
  color: #fff;
  width: 54px;
  height: 54px;
  line-height: 18px;
  padding: 6px;
  font-size: 22px;
  border: 4px solid #f7f7f7;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  position: absolute;
  color: #f7f7f7;
  top: 16px;
  right: -73px;
}
.blog .timeline > li > .timeline-badge span {
  font-size: 13px;
  font-weight: normal;
  display: block;
}
.blog .timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.blog .timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.blog .timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.blog .timeline-title {
  margin-top: 0;
  color: inherit;
}
.blog .timeline-body > p,
.blog .timeline-body > ul {
  padding: 20px;
  margin-bottom: 0;
}
.blog .timeline-body > p + p {
  margin-top: 5px;
}
.blog .timeline-footer {
  padding: 20px;
  background-color: #f4f4f4;
}
.blog .timeline-footer > a {
  cursor: pointer;
  text-decoration: none;
}
.blog .timeline > li.timeline-inverted {
  float: right;
  clear: right;
  margin-top: 30px;
  margin-bottom: 0px;
}
.blog .timeline > li:nth-child(2) {
  margin-top: 60px;
}
.blog .timeline > li:nth-child(3) {
  margin-top: 60px;
}
.blog .timeline .entry-title i {
  font-size: 24px;
}
.blog .timeline-inverted .timeline-badge {
  margin-top: 70px;
}
.blog .timeline li.timeline-inverted .timeline-panel:after {
  top: 98px;
}
.blog .timeline li.timeline-inverted .timeline-panel:before {
  top: 97px;
}
.blog .timeline > li.timeline-inverted > .timeline-badge {
  left: -73px;
}
.blog .timeline li.entry-date {
  top: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 100%;
}
.blog .timeline li.entry-date span {
  background: #f7f7f7;
  color: #363636;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 10px 25px;
  border-radius: 30px;
}
.blog .timeline li.entry-date-bottom {
  bottom: -30px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 100%;
}
.blog .timeline li.entry-date-bottom a {
  background: #f7f7f7;
  padding: 7px 10px;
  color: #363636;
  font-size: 16px;
  padding: 10px 25px;
  border-radius: 30px;
}
.blog .timeline li.entry-date-bottom a:hover {
  background: #3952d0;
  color: #fff;
}
.blog .timeline li:hover .timeline-panel {
  border-color: #3952d0;
}
.blog .timeline li:hover .timeline-badge a {
  color: #fff;
}
.blog .timeline li:hover .timeline-badge {
  background: #3952d0;
  border-color: #3952d0;
}
.blog .timeline li:hover .timeline-panel:before {
  border-left-color: #3952d0;
  border-right-color: #3952d0;
}
.blog .blog.timeline .entry-quote p {
  font-size: 16px;
}

/* blog 2 column masonry left sidebar */
.blog.timeline-sidebar .timeline > li {
  width: 89%;
}
.blog.timeline-sidebar .timeline li .blog-entry-grid .grid-post li {
  width: 50%;
}
.blog.timeline-sidebar .timeline:before {
  left: 54px;
}
.blog.timeline-sidebar .timeline li.entry-date {
  text-align: left;
}
.blog.timeline-sidebar .timeline li.entry-date-bottom {
  text-align: left;
}
.blog.timeline-sidebar .timeline li.timeline-inverted .timeline-badge {
  left: -66px;
}
.blog.timeline-sidebar .timeline > li.timeline-inverted {
  margin-bottom: 0px;
}
.blog.timeline-sidebar .timeline > li:nth-child(3) {
  margin-top: 30px;
}
.blog.timeline-sidebar .timeline li.entry-date {
  top: 3px;
}
.blog.timeline-sidebar .timeline li.entry-date span {
  font-size: 14px;
  padding: 10px 25px;
}
.blog.timeline-sidebar .timeline li.entry-date-bottom {
  bottom: -48px;
}
.blog.timeline-sidebar .timeline li.entry-date-bottom a {
  font-size: 14px;
  padding: 10px 25px;
}
.blog.timeline-sidebar .timeline li .share ul li {
  width: auto;
}
.widget-title {
  border-bottom: 1px solid rgba(34, 35, 40, 0.13);
  padding: 0 0 25px;
}

/*blog-single*/
.blog-single .blog-entry {
  box-shadow: none;
}
.blog-single .blockquote {
  font-style: italic;
  line-height: 30px;
  background: #ffffff !important;
}
.blog-single .blog-entry.blockquote blockquote {
  background: #ffffff;
  color: #626262;
}
.blog-single .blog-entry.blockquote blockquote cite {
  color: #3952d0;
}
.blog-single .entry-share .tags {
  display: inline-block;
}
.blog-single .blog-entry.blockquote blockquote:before {
  color: #323232;
  top: 20px;
}

/*blog-comments*/
.comments-1 .comments-photo {
  width: 106px;
  height: auto;
  float: left;
  border: 5px solid #f7f7f7;
  margin-right: 20px;
}
.comments-1 .comments-photo img {
  width: 100%;
}
.comments-1 .comments-info {
  display: table;
  background: #f7f7f7;
  padding: 20px;
  margin: 20px 0;
}
.comments-1 .comments-info h4 {
  display: inline-block;
}
.comments-1 .comments-info span {
  font-size: 13px;
  color: #626262;
  font-weight: 500;
}
.comments-1 .comments-info a {
  color: #3952d0;
}
.comments-1 .comments-info a:hover {
  color: #363636;
}
.comments-1.comments-2 {
  padding-left: 127px;
}
.comments-1.comments-2 .comments-info {
  background: #f7f7f7;
  margin-bottom: 0px;
  padding: 20px 20px 10px;
}

/*************************************
	    	    Error Block
**************************************/

.logo a {
  position: relative;
  z-index: 9;
}
.error-block .error-text {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  z-index: -1;
}
.error-block h1 {
  font-size: 88px;
  padding-top: 110px;
}
.error-block {
  position: relative;
  z-index: 9;
  padding: 20px 0 130px 0px;
}
.error-block p {
  font-size: 32px;
}
.error-block .error-text h2 {
  font-size: 420px;
  font-weight: 600;
  line-height: 300px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
}
.error-block .error-text span {
  display: inline-block;
  position: absolute;
  right: -50px;
  top: 109px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  font-size: 134px;
  line-height: 100px;
  color: rgba(0, 0, 0, 0.1);
  font-weight: bold;
}
.error-text-02 {
  position: relative;
  text-align: center;
}
.error-text-02 h2 {
  font-size: 420px;
  line-height: 300px;
  color: rgba(255, 255, 255, 0.2);
  position: relative;
  left: -70px;
  top: 0;
}
.error-text-02 span {
  display: inline-block;
  position: absolute;
  right: -50px;
  top: 109px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  font-size: 134px;
  line-height: 100px;
  color: rgba(255, 255, 255, 0.2);
  font-weight: bold;
}

/*************************************
	    	    Coming Soon
**************************************/
.coming-soon .coming-soon-form input {
  color: #626262;
}
.coming-soon-form input {
  width: 50%;
  color: #fff;
  display: block;
  margin: 0 auto;
}
.coming-soon-form.contact-form input {
  width: 50%;
  display: block;
  margin: 0 auto;
}

/*************************************
	    	 Register
**************************************/
.register-form .section-field {
  display: block;
}
.register-form .field-widget {
  position: relative;
}
.register-form .field-widget input {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 20px;
}
.register-form .field-widget select {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 15px;
}
.register-form .field-widget input.gui-input {
  padding-left: 100px;
}
.register-form .field-widget .button {
  background: #666;
  height: 46px;
  left: 0;
  margin: 2px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 115px;
}
.register-form .nice-select {
  float: none;
  margin-bottom: 20px;
  background: #f6f7f8;
  border: 0;
  color: #000;
}
.remember-checkbox a {
  color: #3952d0;
}
.remember-checkbox label {
  position: relative;
  padding-left: 30px;
  font-size: 14px;
  cursor: pointer;
}
.remember-checkbox label:before,
label:after {
  font-family: FontAwesome;
  font-size: 17px;
  position: absolute;
  top: -3px;
  left: 0;
  padding-left: 2px;
  border-radius: 6px;
}
.remember-checkbox label:before {
  border: 2px solid #3952d0;
  content: "";
  height: 20px;
  margin-top: 3px;
  width: 20px;
}
.remember-checkbox label:after {
  content: "\f00c";
  max-width: 0;
  overflow: hidden;
  opacity: 0.5;
  transition: all 0.35s;
  -webkit-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
}
.remember-checkbox input[type="checkbox"] {
  display: none;
}
.remember-checkbox input[type="checkbox"]:checked + label:after {
  max-width: 25px;
  opacity: 1;
}

/*all id*/
#one + label:before,
#one + label:after {
  color: #3952d0;
}
#two + label:before,
#two + label:after {
  color: #3952d0;
}
#three + label:before,
#three + label:after {
  color: hsl(120, 45%, 40%);
}
#four + label:before,
#four + label:after {
  color: hsl(180, 45%, 40%);
}
#five + label:before,
#five + label:after {
  color: hsl(240, 45%, 40%);
}
#six + label:before,
#six + label:after {
  color: hsl(300, 45%, 40%);
}

/*login-2-social*/
.login-social li {
  display: inline-block;
  margin: 0px 8px;
}
.login-social li a {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.login-social li a.fb {
  display: block;
  padding: 12px 20px;
  color: #fff;
  font-size: 14px;
  background: #4c70ad;
}
.login-social li a.twitter {
  display: block;
  padding: 12px 20px;
  color: #fff;
  font-size: 14px;
  background: #25b6e6;
}
.login-social li a.pinterest {
  display: block;
  padding: 12px 20px;
  color: #fff;
  font-size: 14px;
  background: #dd4b39;
}
.login-social li i {
  border-right: 1px solid #fff;
  padding-right: 10px;
  margin-right: 10px;
}
.login-social li a.fb:hover,
.login-social li a.twitter:hover,
.login-social li a.pinterest:hover {
  background: #3952d0;
}

/*login 02*/
.login-bg {
  background: #ffffff;
  border-radius: 10px;
}
.login-bg .login-title {
  background: #3952d0;
  padding: 30px;
  border-radius: 10px 10px 0 0;
}
.login-bg .login-form {
  padding: 40px 30px;
}
.login-bg .login-social {
  padding: 30px;
  background: #f6f7f8;
  border-radius: 0 0 10px 10px;
}
.login-bg .login-form .form-check-input {
  margin-left: 0;
}
.login-bg .login-form .form-check-label {
  padding-left: 20px;
}

/*login 02*/
.register-bg {
  background: #ffffff;
  border-radius: 10px;
}
.register-bg .register-title {
  background: #3952d0;
  padding: 30px;
  border-radius: 10px 10px 0 0;
}
.register-bg .register-form {
  padding: 30px 30px 50px;
}
.register-bg .register-form .form-check-input {
  margin-left: 0;
}
.register-bg .register-form .form-check-label {
  padding-left: 20px;
}

/*************************************
	    	 Maintenance
**************************************/
img.logo-small {
  height: 40px;
  margin: 30px 0;
}
.maintenance-main i {
  font-size: 100px;
  margin-bottom: 10px;
  color: #e4e4e4;
}
.maintenance-main h1 {
  font-size: 62px;
  text-transform: uppercase;
  font-weight: bold;
}
.maintenance-main p {
  font-size: 19px;
  line-height: 35px;
  margin-bottom: 15px;
}
.maintenance-form {
  width: 76%;
  margin: 0 auto;
}
.maintenance-form input {
  width: 50%;
  margin: 0 auto;
}

/*maintenance 2 */
.maintenance-main h1 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}
.maintenance-contant p {
  line-height: 25px;
  margin-top: 20px;
  font-size: 14px;
}
.progress-new {
  margin-top: 50px;
  height: 50px;
  background: #f6f7f8;
  border-radius: 0;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.progress-bar-new {
  line-height: 50px;
  background-color: #3952d0;
  color: #ffffff;
  font-size: 20px;
  padding-left: 20px;
}
.progress-bar-new span {
  position: absolute;
  right: 30px;
  color: #323232;
  font-weight: bold;
  font-size: 20px;
}
.progress-bar-striped,
.progress-striped .progress-bar-new {
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.06) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.06) 50%,
    rgba(0, 0, 0, 0.06) 75%,
    transparent 75%,
    transparent
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.06) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.06) 50%,
    rgba(0, 0, 0, 0.06) 75%,
    transparent 75%,
    transparent
  );
  background-image: -ms-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.06) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.06) 50%,
    rgba(0, 0, 0, 0.06) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.06) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.06) 50%,
    rgba(0, 0, 0, 0.06) 75%,
    transparent 75%,
    transparent
  );
}

/*************************************
	    	 Our History
**************************************/
.our-history .timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}
.our-history .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #3952d0;
  left: 50%;
  margin-left: -0.5px;
}
.our-history .timeline > li {
  margin-bottom: 20px;
  position: relative;
}
.our-history .timeline > li:before,
.our-history .timeline > li:after {
  content: " ";
  display: table;
}
.our-history .timeline > li:after {
  clear: both;
}
.our-history .timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  padding: 15px 10px 25px 10px;
  position: relative;
}
.our-history .timeline > li > .timeline-panel:before {
  position: absolute;
  top: 23px;
  right: -25px;
  display: inline-block;
  width: 25px;
  height: 2px;
  background: #f7f7f7;
  content: " ";
}
.our-history .timeline > li > .timeline-badge {
  color: #fff;
  width: 18px;
  height: 18px;
  line-height: 10px;
  font-size: 0.7em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  border: 3px solid #3952d0;
  margin-left: -9px;
  background-color: #fff;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: 0 0 0 5px #fff;
}
.our-history .timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.our-history .timeline-body > p + p {
  margin-top: 5px;
}
.our-history .timeline > li {
  text-align: right;
}
.our-history .timeline > li.timeline-inverted {
  text-align: left;
}
.our-history .timeline .timeline-arrow {
  position: absolute;
  width: 100%;
  left: 1px;
  right: 0;
  text-align: center;
  color: #f7f7f7;
  bottom: -29px;
}
.our-history .timeline > li > .timeline-badge p {
  width: 200px;
  margin-top: 0px;
  margin-left: 25px;
  font-size: 17px;
  font-weight: bold;
  text-align: left;
}
.our-history .timeline > li.timeline-inverted .timeline-badge p {
  text-align: right;
  float: right;
  margin-right: 25px;
}

/***********************************
         Process
************************************/
.process {
  margin: 0px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  position: relative;
  width: 100%;
  float: left;
  padding-bottom: 50px;
  padding-top: 50px;
  min-height: inherit;
}
.process.left {
  border-right: 0;
  border-top: 0;
}
.process.right {
  border-left: 0;
  border-top: 0;
}
.process .border-area.left-bottom {
  position: absolute;
  bottom: -2px;
  background: #fff;
  height: 110px;
  width: 110px;
  left: -2px;
  z-index: 99;
}
.process .border-area.left-bottom:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0;
  top: 0;
  border-bottom-left-radius: 70px;
  border-left: 2px dashed rgba(0, 0, 0, 0.3);
  border-bottom: 2px dashed rgba(0, 0, 0, 0.3);
}
.process .border-area.right-top {
  position: absolute;
  top: -2px;
  background: #fff;
  height: 110px;
  width: 110px;
  right: -2px;
}
.process .border-area.right-top:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0;
  top: 0;
  border-top-right-radius: 70px;
  border-right: 2px dashed rgba(0, 0, 0, 0.3);
  border-top: 2px dashed rgba(0, 0, 0, 0.3);
}
.process .border-area.right-bottom {
  position: absolute;
  bottom: -2px;
  background: #fff;
  height: 110px;
  width: 110px;
  right: -2px;
}
.process .border-area.right-bottom:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0;
  top: 0;
  border-bottom-right-radius: 70px;
  border-right: 2px dashed rgba(0, 0, 0, 0.3);
  border-bottom: 2px dashed rgba(0, 0, 0, 0.3);
}
.process .border-area.left-top {
  position: absolute;
  top: -2px;
  background: #fff;
  height: 110px;
  width: 110px;
  left: -2px;
}
.process .border-area.left-top:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0;
  top: 0;
  border-top-left-radius: 70px;
  border-left: 2px dashed rgba(0, 0, 0, 0.3);
  border-top: 2px dashed rgba(0, 0, 0, 0.3);
}
.process:first-child {
  padding-top: 0;
}
.process.left:first-child .process-step {
  top: 0;
}
.process:last-child {
  border-bottom: 0;
}
.process:last-child .border-area.left-bottom {
  bottom: 0;
}
.process:last-child .border-area.left-bottom:before {
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.process.left .process-step {
  position: absolute;
  top: 50px;
  left: -35px;
  z-index: 999;
}
.process.right .process-step {
  position: absolute;
  top: 50px;
  right: -35px;
  z-index: 99;
}
.process.left .process-content {
  padding-left: 80px;
}
.process.right .process-content {
  padding-right: 80px;
}
.process .process-step strong {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background: #3952d0;
  border-radius: 100%;
  display: inline-block;
  z-index: 99;
  font-size: 20px;
  color: #fff;
}
.process .process-content {
  position: relative;
  z-index: 99;
}
.process .process-content span {
  font-size: 80px;
  line-height: 80px;
  color: #3952d0;
}
.process.left .process-content .process-icon {
  display: table-cell;
  padding-right: 30px;
}
.process.left .process-content .process-info {
  display: table-cell;
  vertical-align: top;
}
.process.right .process-content .process-icon {
  float: right;
  padding-left: 30px;
}
.process.right .process-content .process-info {
  display: table-cell;
  vertical-align: top;
}

/*************************************
	    	 Careers
**************************************/
.careers .acd-des span {
  display: block;
}

/*************************************
          Sitemap
**************************************/
.sitemap h4 {
  margin-bottom: 25px;
  text-transform: capitalize;
}
.sitemap h4 i {
  padding-right: 10px;
}
.sitemap ul li {
  display: block;
  line-height: 34px;
}
.sitemap ul li i {
  padding-right: 10px;
}
.sitemap ul li a {
  font-size: 14px;
  color: #626262;
  text-transform: capitalize;
}
.sitemap ul li a:hover {
  color: #3952d0;
}

/*************************************
         Privacy-Policy
**************************************/
.privacy-policy ul li {
  font-size: 14px;
  line-height: 30px;
}
.privacy-policy ul li i {
  padding-right: 10px;
}

/*************************************
        Terms and Conditions
**************************************/
.terms-and-conditions ul li {
  font-size: 14px;
  line-height: 30px;
}
.terms-and-conditions ul li i {
  padding-right: 10px;
}

/*************************************
          Special-Eature
**************************************/
.special-feature {
  position: relative;
  z-index: 99;
  top: -117px;
  margin-bottom: -117px;
}
.special-feature .feature-text {
  padding: 40px;
}

/*************************
   BG - Images
*************************/
.bg.fixed {
  background-color: rgba(0, 0, 0, 0);
  background-origin: initial;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
  z-index: 0;
  background-attachment: fixed;
}
.bg {
  background-origin: initial;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
  z-index: 0;
}
.parallax {
  background-attachment: fixed !important;
  background-color: rgba(0, 0, 0, 0);
  background-origin: initial;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
  z-index: 0;
}
.bg-cover {
  background-size: cover !important;
  background-attachment: inherit !important;
}

/*************************************
          Footer
**************************************/
.footer.bg-black {
  background: #120b00;
}
.footer.bg-black p {
  color: rgba(255, 255, 255, 0.5);
}
.footer.bg-black .social-icons li a {
  color: rgba(255, 255, 255, 0.5);
}
.footer p {
  font-size: 14px;
}
.footer-logo img {
  width: 65%;
}
.footer h6 {
  font-size: 14px;
}
.addresss-info li {
  font-size: 14px;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.5);
  list-style: none;
}
.addresss-info i {
  color: rgba(255, 255, 255, 0.5);
  padding-right: 5px;
  float: left;
  display: table-cell;
  width: 30px;
  line-height: 23px;
  font-size: 14px;
}
.addresss-info p {
  display: table;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0px;
}
.footer a {
  font-size: 14px;
}

/*footer-Newsletter*/
.footer-Newsletter {
  display: inline-block;
  width: 100%;
}
.footer-useful-link.footer-hedding ul {
  margin-top: -6px;
}
.footer-Newsletter .form-control {
  border-left: 0px;
  height: 45px;
  border-radius: 4px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
  border: none;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 25px;
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-Newsletter .form-control:focus {
  border: 0;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.1);
}
.footer-Newsletter .button {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  padding: 10px 18px;
  font-size: 12px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-Newsletter .button:hover {
  color: #ffffff;
}
.footer-useful-link ul li {
  list-style: none;
  margin-bottom: 12px;
}
.footer-useful-link ul li a {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  vertical-align: top;
  display: block;
  line-height: 26px;
}
.footer-useful-link ul li a:hover {
  color: #3952d0;
}

/*footer-widget*/
.footer-widget {
  padding: 20px 0;
  border-top: 1px solid #262626;
}
.footer-widget p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.5px;
}
.footer-widget a {
  color: #3952d0;
}
.footer-widget a:hover {
  color: #3952d0;
}
.footer-widget-social {
  margin-top: 5px;
}
.footer-widget-social ul {
  padding-left: 0;
}
.footer-widget-social li {
  display: inline-block;
}
.footer-widget-social a i {
  display: inline-block;
  color: rgba(255, 255, 255, 0.5);
  background: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 37px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-widget-social a i:hover {
  background: #3952d0;
  color: #fff;
}
.footer .footer-nav ul li {
  display: inline-block;
}
.footer .footer-nav ul li a {
  margin: 0 5px;
  color: #323232;
}
.footer .footer-nav ul li a:hover {
  color: #3952d0;
}
.footer .social ul li {
  display: inline-block;
}
.footer .social ul li a i {
  font-size: 14px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  float: none;
  color: #323232;
}
.footer .social ul li a i:hover {
  color: #3952d0;
}
.footer .social ul {
  background: #f6f7f8;
  display: inline-block;
  border-radius: 30px;
  padding: 0px 10px;
}
.footer .usefull-link ul li {
  display: block;
  border-bottom: 0px;
  line-height: 24px;
  padding: 5px 0;
}
.footer .usefull-link ul li a {
  color: #323232;
}
.footer .usefull-link ul li a:hover {
  color: #3952d0;
}
.footer .copyright {
  padding: 30px 0 20px;
}
.footer .copyright ul li a {
  color: #323232;
}
.footer .copyright ul li a:hover {
  color: #3952d0;
}
img#logo-footer {
  height: 50px;
  width: auto;
}
.footer.light .footer-nav ul li a {
  color: #fff;
}
.footer.light .footer-nav ul li a:hover {
  color: #323232;
}
.footer.light h6 {
  color: #fff;
}
.footer.light .about-content p {
  color: #fff;
}
.footer.light .usefull-link ul li a {
  color: #fff;
}
.footer.light .usefull-link ul li a:hover {
  color: #323232;
}
.footer.light .copyright ul li {
  color: #fff;
}
.footer.light .copyright ul li a {
  color: #ffffff;
  font-size: 14px;
}
.footer.light .copyright ul li a:hover {
  color: #323232;
}
.footer.light.footer-topbar .copyright ul li {
  color: #ffffff;
}
.footer.light .copyright p {
  color: #fff;
}
.footer.light .social ul li a i {
  color: #3952d0;
}
.footer.light .social ul li a:hover i {
  color: #323232;
}
.footer .footer-text p {
  color: rgba(255, 255, 255, 0.5) !important;
  padding-top: 10px;
}
.footer .footer-social ul li a {
  color: rgba(255, 255, 255, 0.5) !important;
  padding-top: 10px;
}
.footer .footer-social ul li a:hover {
  color: #3952d0 !important;
}
.footer.footer-topbar .copyright ul li {
  color: rgba(255, 255, 255, 0.5) !important;
  display: inline-block;
  float: none;
}
.footer.footer-one-page .contact-add i {
  font-size: 20px;
}
.footer.footer-one-page h5 {
  color: rgba(255, 255, 255, 0.5);
}
.footer.footer-one-page p {
  color: rgba(255, 255, 255, 0.5);
}

/* footer transparent*/
.footer.transparent h6,
.footer.transparent p,
.footer.transparent ul li a,
.footer.transparent span {
  color: #ffffff;
}

/*widget-gallery*/
.footer .footer-gallery ul li {
  float: left;
  width: 58px;
  margin: 2px;
  list-style: none;
}
.footer .footer-tags li {
  display: inline-block;
}
.footer .footer-tags li a {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 8px 16px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 2px;
}
.footer .footer-tags li a:hover {
  background: #3952d0;
}

/*footer-social-big*/
.footer-social-big ul li {
  display: inline-block;
  padding: 10px;
  width: 67px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
}
.footer-social-big ul li.facebook {
  background: #5d82d1;
}
.footer-social-big ul li.pinterest {
  background: #e13138;
}
.footer-social-big ul li.linkedin {
  background: #238cc8;
}
.footer-social-big ul li i {
  color: #ffffff;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin: 0 auto;
}
.footer-social-big ul li span {
  display: block;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  font-size: 12px;
}

/*footer-box*/
.footer-box {
  background: rgba(102, 102, 102, 0.2);
  padding: 30px;
  clear: both;
  margin-bottom: 40px;
  margin-top: 60px;
}
.footer-box .box-content {
  display: table-cell;
  vertical-align: top;
}
.footer-box .box-link {
  display: table-cell;
  padding-left: 20px;
  vertical-align: middle;
}
.footer-box .box-link a {
  display: block;
  padding: 8px 16px;
  width: 140px;
}

/*google-map*/
.google-map {
}
.google-map .row [class*="col-"] {
  min-height: 0;
}
.map-icon {
  position: relative;
  z-index: 99;
}
.google-map .map-icon:before {
  cursor: pointer;
  content: "\f041";
  font-family: "FontAwesome";
  font-size: 30px;
  font-weight: normal;
  right: 0;
  position: absolute;
  text-indent: 0;
  top: -32px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #ffffff;
  color: #3952d0;
  text-align: center;
}
.google-map.google-map-open .map-icon::before {
  content: "\f00d";
}
.google-map .map-open {
  height: 0;
  overflow: hidden;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  transition: all 900ms ease;
}
.google-map .map-open iframe {
  height: 300px;
}
.google-map-open .map-open {
  overflow: visible;
  height: 300px;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.pos-r {
  position: relative;
}
.img-side img {
  max-width: 100%;
}
.img-side.img-left {
  left: 0;
}
.img-side {
  padding: 0;
  position: absolute !important;
  top: 0px;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}
.font-bold {
  font-weight: 600;
}
.no-gutter {
  margin-right: 0;
  margin-left: 0;
}
.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/*youtube-video*/
.popup-video {
  position: relative;
}
.popup-video .play-video {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.popup-video .play-video {
  width: 50px;
  height: 50px;
}
.popup-video .play-video a {
  width: 50px;
  height: 50px;
}
.popup-video .play-video a i {
  line-height: 50px;
}
.popup-video .play-video:hover {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  background-color: #ffffff;
}
.popup-video .play-video:hover a {
  color: #3952d0;
}
.play-video {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.play-video {
  background-color: #3952d0;
  -webkit-box-shadow: 2px 6px 18px rgba(255, 255, 255, 0.55);
  -moz-box-shadow: 2px 6px 18px rgba(255, 255, 255, 0.55);
  box-shadow: 2px 6px 18px rgba(255, 255, 255, 0.55);
}
.play-video a {
  color: #fff;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 50%;
  padding-left: 5px;
  transform: translateX(-50%);
}
.play-video a i {
  line-height: 100px;
  font-size: 30px;
}
.play-video:hover a {
  color: #3952d0;
}
.play-video:after {
  content: "";
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
  -moz-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}
.play-video:hover {
  background: #ffffff;
  color: #3952d0;
  border-color: transparent !important;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 2px 6px 18px transparent !important;
  -moz-box-shadow: 2px 6px 18px transparent !important;
  box-shadow: 2px 6px 18px transparent !important;
}
.play-video:hover:after {
  -webkit-animation: sonarEffect 1.3s ease-out 75ms;
  -moz-animation: sonarEffect 1.3s ease-out 75ms;
  animation: sonarEffect 1.3s ease-out 75ms;
}
@-webkit-keyframes sonarEffect {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff,
      0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    -webkit-transform: scale(1.5);
    opacity: 0;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff,
      0 0 0 10px rgba(255, 255, 255, 0.5);
  }
}
@-moz-keyframes sonarEffect {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff,
      0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    -moz-transform: scale(1.5);
    opacity: 0;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff,
      0 0 0 10px rgba(255, 255, 255, 0.5);
  }
}
@keyframes sonarEffect {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff,
      0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff,
      0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

/*************************************
          Sidebar
**************************************/

/*widget-servies*/
.sidebar-widget {
  margin-bottom: 40px;
}
.widget-services {
  border: 1px solid #ccc;
  border-top: 5px solid #3952d0;
}
.widget-services li {
  list-style-type: none;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.widget-services li:last-child {
  border-bottom: 0;
}
.widget-services li a {
  color: #333;
  display: block;
  list-style-type: none;
  padding: 20px;
  font-weight: 700;
}
.widget-services li a i {
  float: right;
}
.widget-services li a:hover {
  color: #3952d0;
}

/*widget-testimonial*/
.sidebar-widget .owl-carousel .owl-dots {
  bottom: 10px;
}
.columns .bg-gray {
  padding: 10px;
}
.card.box-shadow.bg-theme a:hover,
.card.box-shadow.bg-theme a:focus,
.card.box-shadow.bg-theme a:active,
.card.bg-theme a:hover,
.card.bg-theme a:active,
.card.bg-theme a:focus {
  color: #323232;
}

/*ADD*/
.isotope.columns-3 .grid-item {
  width: 33.33%;
  padding: 10px;
}
.custom-content {
  margin-top: -120px;
  position: relative;
  z-index: 9;
  padding: 30px;
}
